import { useState } from "react";
import toast from "cogo-toast";
import logo from "../assets/images/logo.png";
import { auth } from "../services/api";

function Login() {
  const [loginPayload, setLoginPayload] = useState({ email: "", password: "" });

  function handleChange(event, key) {
    setLoginPayload({
      ...loginPayload,
      [key]: event.target.value,
    });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    await auth.login(loginPayload).then(async (response) => {
      if (response.status < 200 || response.status >= 400) {
        toast.warn("login gagal");

        return {};
      }

      const json = await response.json();
      if (json && json.status === "error") {
        toast.warn(`login gagal: ${json.message}`);

        return {};
      }

      return response;
    });
  }

  return (
    <div className="login-container">
      <form className="login-form">
        <div className="login-form-logo text-center">
          <img width="90%" height="100%" src={logo} alt="logo" />
        </div>
        <div className="form-group">
          <input
            type="email"
            placeholder="EMAIL"
            className="form-control login-form-input input-icon-left icon-username"
            onChange={(event) => handleChange(event, "email")}
            value={loginPayload.email}
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            placeholder="PASSWORD"
            className="form-control login-form-input input-icon-left icon-password"
            onChange={(event) => handleChange(event, "password")}
            value={loginPayload.password}
          />
        </div>
        <div className="login-form-footer">
          <button
            className="btn btn-primary btn-block login-form-button"
            type="submit"
            onClick={handleSubmit}
          >
            LOGIN
          </button>
        </div>
      </form>
    </div>
  );
}

export default Login;
