import { useEffect, useRef, useState } from "react";

function DropdownButton({ className, items, placeholder, onChange }) {
  const nodeRef = useRef();

  const [show, setShow] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(
    placeholder || (items && items[0] ? items[0].label : "")
  );

  function toggleDropdown() {
    setShow(!show);
  }

  function handleClickOutside(event) {
    if (nodeRef.current.contains(event.target)) {
      return;
    }

    setShow(false);
  }

  useEffect(() => {
    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]);

  function handleClickDropdownItem(item) {
    if (onChange) {
      onChange(item);
    }

    setSelectedLabel(item.label);
    setShow(false);
  }

  return (
    <div ref={nodeRef} className="dropdown">
      <button type="button" className={className} onClick={toggleDropdown}>
        {selectedLabel}
      </button>
      <ul className={`dropdown-menu ${show ? "show" : ""}`}>
        {items.map((item) => {
          return (
            <li key={item.key}>
              <button
                type="button"
                className="dropdown-item"
                onClick={() => handleClickDropdownItem(item)}
              >
                {item.label}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default DropdownButton;
