import { get, post, put, del, withAuth } from "./base";

const getSingle = (id) => {
  return get(`stock-template-group/${id}`, withAuth());
};

const createSingle = (options) => {
  return post("stock-template-group", withAuth(options));
};

const updateSingle = (id, options) => {
  return put(`stock-template-group/${id}`, withAuth(options));
};

const deleteSingle = (id) => {
  return del(`stock-template-group/${id}`, withAuth());
};

export default { getSingle, createSingle, updateSingle, deleteSingle };
