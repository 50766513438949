import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import toast from "cogo-toast";
import MainLayout from "./layouts/MainLayout";
import AsyncDropdownSearchable from "../components/AsyncDropdownSearchable";
import PrimaryButton from "../components/PrimaryButton";
import { Add, Dash } from "../components/Icon";
import { stockTemplate, user, warehouse } from "../services/api";

async function fetchTemplate(keyword = "") {
  const response = await stockTemplate.getSearch(keyword);

  if (!response || (response && !response.ok)) {
    return {};
  }

  const result = await response.json();
  return result;
}

function mapToDropdownTemplate(result) {
  const mappedData = [];

  if (result && result.data && result.data.data) {
    result.data.data.forEach((item) => {
      mappedData.push({
        key: item.id,
        label: item.name,
      });
    });
  }

  return mappedData;
}

async function fetchWarehouse(keyword = "") {
  const response = await warehouse.getSearch(keyword);

  if (!response || (response && !response.ok)) {
    return {};
  }

  const result = await response.json();
  return result;
}

function mapToDropdownWarehouse(result) {
  const mappedData = [];

  if (result && result.data) {
    result.data.forEach((item) => {
      mappedData.push({
        key: item.warehouse_id,
        label: item.warehouse_name,
      });
    });
  }

  return mappedData;
}

function filterSelectedWarehouse(items, selectedItems) {
  let filteredData = [];

  if (items) {
    filteredData = items.filter((item) => {
      return !selectedItems.includes(item.key);
    });
  }

  return filteredData;
}

function ManageCustomerNew() {
  const history = useHistory();

  const [customerData, setCustomerData] = useState({
    email: "",
    password: "",
    confirm_password: "",
    name: "",
    stock_template_id: null,
  });
  const [warehouses, setWarehouses] = useState([
    {
      id: 1,
      warehouse_id: null,
    },
  ]);
  const [submitted, setSubmitted] = useState(false);

  async function templateOptions(keyword) {
    const result = await fetchTemplate(keyword);

    return mapToDropdownTemplate(result);
  }

  async function warehouseOptions(keyword) {
    const result = await fetchWarehouse(keyword);

    const selectedItems = warehouses.map((item) => {
      return item.warehouse_id;
    });

    return filterSelectedWarehouse(
      mapToDropdownWarehouse(result),
      selectedItems
    );
  }

  function handleChangeCustomerData(event, key) {
    event.preventDefault();

    setCustomerData((prevData) => {
      return {
        ...prevData,
        [key]: event.target.value,
      };
    });
  }

  function handleChangeTemplate(option) {
    setCustomerData((prevData) => {
      return {
        ...prevData,
        stock_template_id: option.key,
      };
    });
  }

  function handleChangeWarehouse(option, item) {
    setWarehouses((prevItems) =>
      prevItems.map((prevItem) => {
        if (prevItem.id === item.id) {
          return {
            ...prevItem,
            warehouse_id: option.key,
          };
        }

        return prevItem;
      })
    );
  }

  function handleClickAddWarehouse() {
    setSubmitted(false);
    setWarehouses((prevItems) => {
      const newItem = {
        id: prevItems.length > 0 ? prevItems[prevItems.length - 1].id + 1 : 1,
        warehouse_id: null,
      };

      return [...prevItems, newItem];
    });
  }

  function handleClickDeleteWarehouse(item) {
    setWarehouses((prevItems) =>
      prevItems.filter((prevItem) => {
        return prevItem.id !== item.id;
      })
    );
  }

  function buildPayload() {
    return {
      email: customerData.email,
      password: customerData.password,
      name: customerData.name,
      stock_template_id: customerData.stock_template_id,
      user_warehouses: warehouses.map((item) => {
        return item.warehouse_id;
      }),
    };
  }

  const inputIsFilled = useCallback(() => {
    return (
      warehouses.every((item) => {
        return item.warehouse_id !== null;
      }) &&
      customerData.email !== "" &&
      customerData.password !== "" &&
      customerData.confirm_password !== "" &&
      customerData.name !== "" &&
      customerData.stock_template_id != null
    );
  }, [warehouses, customerData]);

  async function handleClickCreate() {
    setSubmitted(true);
    if (inputIsFilled()) {
      if (customerData.password === customerData.confirm_password) {
        await user.createSingle({ json: buildPayload() }).then((response) => {
          if (response && response.status === 201) {
            toast.success("data berhasil ditambahkan.");
            history.push(`/manage-customer`);
          }
        });
      } else {
        toast.warn("konfirmasi password salah.");
      }
    } else {
      toast.warn("item tidak boleh ada yang kosong.");
    }
    setSubmitted(false);
  }

  return (
    <MainLayout headerTitle="Manage Customers">
      <div className="d-flex">
        <div className="content-container content-gutters-large m-auto width-35em">
          <form>
            <h4 className="font-weight-bold">Tambah User Baru</h4>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                className={`form-control ${
                  submitted && !customerData.email && "is-invalid"
                }`}
                id="email"
                onChange={(event) => handleChangeCustomerData(event, "email")}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                className={`form-control ${
                  submitted && !customerData.password && "is-invalid"
                }`}
                id="password"
                onChange={(event) =>
                  handleChangeCustomerData(event, "password")
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor="confirm-password">Ketik Ulang Password</label>
              <input
                type="password"
                className={`form-control ${
                  submitted && !customerData.confirm_password && "is-invalid"
                }`}
                id="confirm-password"
                onChange={(event) =>
                  handleChangeCustomerData(event, "confirm_password")
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor="customer-name">Nama Customer</label>
              <input
                className={`form-control ${
                  submitted && !customerData.name && "is-invalid"
                }`}
                id="customer-name"
                onChange={(event) => handleChangeCustomerData(event, "name")}
              />
            </div>
            <div className="form-group">
              <label htmlFor="template">Template</label>
              <AsyncDropdownSearchable
                id="template"
                toggleClassName={`form-control bg-transparent input-icon-right input-icon-small icon-drop ${
                  submitted && !customerData.stock_template_id && "is-invalid"
                }`}
                dropdownMenuClassName="w-100"
                searchbarClassName="w-100"
                searchbarPlaceholder="Search Template"
                loadItems={templateOptions}
                onChange={handleChangeTemplate}
              />
            </div>
            {warehouses.map((item) => (
              <div className="form-group row" key={item.id}>
                <div className="col">
                  <label htmlFor={`warehouse_${item.id}`}>Warehouse</label>
                  <AsyncDropdownSearchable
                    id={`warehouse_${item.id}`}
                    toggleClassName={`form-control bg-transparent input-icon-right input-icon-small icon-drop ${
                      submitted && item.warehouse_id === null && "is-invalid"
                    }`}
                    dropdownMenuClassName="w-100"
                    searchbarClassName="w-100"
                    searchbarPlaceholder="Search Warehouse"
                    loadItems={warehouseOptions}
                    onChange={(value) => handleChangeWarehouse(value, item)}
                  />
                </div>
                <div className="align-self-end py-1">
                  <Dash
                    className="red-circle cursor-pointer"
                    onClick={() => handleClickDeleteWarehouse(item)}
                  />
                </div>
                <span className="mr-3" />
              </div>
            ))}
            <div className="form-group">
              <PrimaryButton onClick={handleClickAddWarehouse}>
                <Add className="icon-16px" /> Add Warehouse
              </PrimaryButton>
            </div>
            <div className="form-group mt-5">
              <PrimaryButton
                fullWidth
                onClick={handleClickCreate}
                disabled={submitted}
              >
                Create
              </PrimaryButton>
            </div>
          </form>
        </div>
      </div>
    </MainLayout>
  );
}

export default ManageCustomerNew;
