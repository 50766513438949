import ky from "ky";
// import toast from "cogo-toast";
import user from "../../utils/user";

const { REACT_APP_API_URL_PATH } = process.env;

function withAuth(options) {
  return {
    ...options,
    headers: {
      Authorization: `Bearer ${user.getAccessToken()}`,
    },
    hooks: {
      ...(options && options.hooks),
      afterResponse: [
        (_request, _options, response) => {
          if (response.status === 401) {
            user.removeLogin();

            window.location.href = "/login";
          }

          return response;
        },
      ],
    },
  };
}

const { get, post, put, delete: del } = ky.create({
  prefixUrl: REACT_APP_API_URL_PATH,
  throwHttpErrors: false,
  hooks: {
    afterResponse: [
      (_request, _options, response) => {
        if (response.status < 200 || response.status >= 400) {
          // toast.warn("request error.");
        }

        return response;
      },
    ],
  },
  timeout: 1000 * 60 * 5,
});

export { get, post, put, del, withAuth };
