import { useEffect, useMemo } from "react";
import { useTable, usePagination } from "react-table";
import { NextArrow, PreviousArrow } from "./Icon";

function defaultPropGetter() {
  return {};
}

function useInstance(instance) {
  const { allColumns } = instance;

  const rowSpanHeaders = [];

  allColumns.forEach((column) => {
    const { id, enableRowSpan, inheritId } = column;

    if (enableRowSpan === true) {
      rowSpanHeaders.push({
        id,
        topCellValue: null,
        topCellIndex: 0,
        inheritId,
      });
    }
  });

  Object.assign(instance, { rowSpanHeaders });
}

function ControlledTable({
  loading = false,
  columns: controlledColumns,
  data,
  noPagination = false,
  headerClassName,
  header,
  footer,
  getRowProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  hooks = () => {},
  pageCount: controlledPageCount,
  pageIndex: controlledPageIndex = 0,
  pageLength: controlledPageLength,
  pageSize = 10,
  onFetchData = () => {},
  onPageChange = () => {},
  hiddenColumns = [],
  noDataMessage,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    state: { pageIndex },
    rowSpanHeaders,
    setHiddenColumns,
  } = useTable(
    {
      columns: controlledColumns,
      data,
      initialState: { pageIndex: controlledPageIndex, pageSize, hiddenColumns },
      manualPagination: true,
      pageCount: controlledPageCount,
      useControlledState: (state) => {
        return useMemo(
          () => ({
            ...state,
            pageIndex: controlledPageIndex,
          }),
          [state, controlledPageIndex]
        );
      },
    },
    usePagination,
    (_hooks) => {
      _hooks.useInstance.push(useInstance);
    },
    hooks
  );

  function renderPaginationItems() {
    const paginationItems = [];

    // previous page button
    if (pageIndex > 0 && pageCount > 3) {
      paginationItems.push(
        <button
          className="pagination-item"
          key="page-previous"
          type="button"
          onClick={() => onPageChange({ pageIndex: pageIndex - 1 })}
        >
          <PreviousArrow />
        </button>
      );

      paginationItems.push(
        <span
          key="page-ellipsis-previous"
          className="pagination-item-ellipsis"
        />
      );
    }

    let startIndex = pageIndex - 1;
    let endIndex = pageIndex + 1;

    if (endIndex > pageCount - 1) {
      startIndex -= endIndex - pageCount + 1;
      endIndex = pageCount - 1;
    }

    if (startIndex < 0) {
      startIndex = 0;
      endIndex += startIndex + 1;
    }

    if (pageCount > 0 && pageCount < 4) {
      startIndex = 0;
      endIndex = pageCount - 1;
    }

    // numeric page buttons
    for (let i = startIndex; i <= endIndex; i += 1) {
      paginationItems.push(
        <button
          className={`pagination-item${
            i === pageIndex ? " pagination-item-active" : ""
          }`}
          key={`page-numeric-${i}`}
          type="button"
          onClick={() => onPageChange({ pageIndex: i })}
        >
          {i + 1}
        </button>
      );
    }

    // next page button
    if (pageIndex < pageCount - 1 && pageCount > 3) {
      paginationItems.push(
        <span key="page-ellipsis-next" className="pagination-item-ellipsis" />
      );

      paginationItems.push(
        <button
          className="pagination-item"
          key="page-next"
          type="button"
          onClick={() => onPageChange({ pageIndex: pageIndex + 1 })}
        >
          <NextArrow />
        </button>
      );
    }

    return paginationItems;
  }

  useEffect(() => {
    onFetchData({ pageIndex, pageSize });
  }, [onFetchData, pageIndex, pageSize]);

  useEffect(() => {
    setHiddenColumns(hiddenColumns);
  }, []);

  return (
    <>
      <div className={`table-header ${headerClassName}`}>{header}</div>
      <div className="position-relative">
        {loading ? (
          <div className="bg-white-alpha-50 position-absolute w-100 h-100 d-flex align-items-center justify-content-center">
            <div className="spinner-border text-primary" role="status" />
          </div>
        ) : null}
        <div className="table-wrapper">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps([
                        {
                          className: column.className,
                          style: column.style,
                        },
                      ])}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {/* computing cell rowSpan */}
              {page.map((row, index) => {
                prepareRow(row);

                for (let i = 0; i < row.allCells.length; i += 1) {
                  const cell = row.allCells[i];
                  const rowSpanHeader = rowSpanHeaders.find(
                    (item) => item.id === cell.column.id
                  );

                  if (rowSpanHeader) {
                    const { topCellValue, topCellIndex } = rowSpanHeader;

                    // if it is first cell
                    // or if cell value not equal to previous cell
                    // then set rowSpan to 1 and isRowSpanned to false
                    // else grow rowSpan and set isRowSpanned to true
                    if (topCellValue === null || topCellValue !== cell.value) {
                      rowSpanHeader.topCellValue = cell.value;
                      rowSpanHeader.topCellIndex = index;

                      cell.isRowSpanned = false;
                      cell.rowSpan = 1;
                    } else {
                      cell.isRowSpanned = true;
                      page[topCellIndex].allCells[i].rowSpan += 1;
                    }
                  }
                }

                return null;
              })}
              {/* computing cell inherit rowSpan */}
              {page.map((row) => {
                for (let i = 0; i < row.allCells.length; i += 1) {
                  const cell = row.allCells[i];
                  const rowSpanHeader = rowSpanHeaders.find(
                    (item) => item.id === cell.column.id
                  );

                  if (rowSpanHeader && rowSpanHeader.inheritId) {
                    const [inheritedCell] = row.allCells.filter((item) => {
                      return item.column.id === cell.column.inheritId;
                    });

                    cell.isRowSpanned = inheritedCell.isRowSpanned;
                    cell.rowSpan = inheritedCell.rowSpan;
                  }
                }

                return null;
              })}
              {/* render cells */}
              {page.map((row) => {
                return (
                  <tr {...row.getRowProps(getRowProps(row))}>
                    {row.cells.map((cell) => {
                      if (cell.isRowSpanned) return null;
                      return (
                        <td
                          {...cell.getCellProps([
                            {
                              className: cell.column.className,
                              style: cell.column.style,
                            },
                            getColumnProps(cell.column),
                            getCellProps(cell),
                          ])}
                          rowSpan={cell.rowSpan}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {noDataMessage && !loading && data && data.length < 1
            ? noDataMessage
            : null}
        </div>
      </div>
      <div className="table-footer">
        {footer}
        {noPagination ? (
          ""
        ) : (
          <div className="pagination">
            <span className="pagination-caption">
              Showing {controlledPageLength || page.length} Results
            </span>{" "}
            {renderPaginationItems()}
          </div>
        )}
      </div>
    </>
  );
}

export default ControlledTable;
