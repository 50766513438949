import { get, withAuth } from "./base";

const getSearch = (keyword = "", page = 1, signal) => {
  return get(
    `stock-warehouse-admin?search=${keyword}&page=${page}`,
    withAuth({ signal })
  );
};

export default { getSearch };
