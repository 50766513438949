import { useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import { format, isDate } from "date-fns";
import { id } from "date-fns/locale";

function DatePicker({ onDatePick, dateFormat = "y-MM-dd", defaultValue }) {
  const nodeRef = useRef();

  const [calendarVisible, setCalendarVisible] = useState(false);
  const [calendarValue, setCalendarValue] = useState();

  function toggleCalendar() {
    setCalendarVisible(!calendarVisible);
  }

  function dateFormatter(date) {
    if (isDate(date)) {
      return format(date, dateFormat, {
        locale: id,
      });
    }

    return "";
  }

  function handleClickDay(value) {
    setCalendarValue(value);

    if (onDatePick) {
      onDatePick(value);
    }

    toggleCalendar();
  }

  function handleClickOutside(event) {
    if (nodeRef.current.contains(event.target)) {
      return;
    }

    setCalendarVisible(false);
  }

  useEffect(() => {
    if (calendarVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [calendarVisible]);

  useEffect(() => {
    setCalendarValue(defaultValue);
  }, []);

  return (
    <div className="date-picker-container">
      <div ref={nodeRef} className="date-picker">
        <input
          className="date-picker-input input-icon-right input-icon-small icon-drop"
          readOnly
          placeholder="- Select Date -"
          value={dateFormatter(calendarValue)}
          onClick={() => toggleCalendar()}
        />
        {calendarVisible ? (
          <Calendar
            defaultValue={defaultValue}
            className="z-index-1"
            locale="id-ID"
            value={calendarValue}
            onClickDay={handleClickDay}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default DatePicker;
