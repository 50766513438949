function StockOutInvoice({ data = {} }) {
  return (
    <div className="border mb-2 pb-5">
      <table className="m-0 table table-borderless">
        <tbody>
          <tr>
            <td className="text-right align-middle" style={{ width: "25%" }}>
              {}
            </td>
            <td className="text-center" style={{ width: "50%" }}>
              <strong>PT. BANJAR BEARING SENTOSA</strong>
              <br />
              Jl. Gatot Subroto No. 74 RT. 032 - Kuripan - Banjarmasin Timur
              <br />
              KODYA BANJARMASIN 70238 Telp. (+62 511) 3350564
              <br />
              E-mail: bbsbjm@banjarbearing.com
            </td>
            <td style={{ width: "25%" }}>{}</td>
          </tr>
        </tbody>
      </table>
      <table className="m-0 table table-borderless">
        <tbody>
          <tr>
            <td style={{ width: "35%" }}>
              <table className="m-0 table table-borderless table-sm">
                <tbody>
                  <tr>
                    <td className="text-nowrap">No. Nota</td>
                    <td style={{ width: "0.5em" }}>:</td>
                    <td style={{ width: "100%" }}>
                      <strong>{data.INVOICENO}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-nowrap">Jatuh Tempo</td>
                    <td style={{ width: "0.5em" }}>:</td>
                    <td style={{ width: "100%" }}>{data.DUEDATE}</td>
                  </tr>
                  <tr>
                    <td className="text-nowrap">Kode Sales</td>
                    <td style={{ width: "0.5em" }}>:</td>
                    <td style={{ width: "100%" }}>{}</td>
                  </tr>
                  <tr>
                    <td className="text-nowrap">Operator</td>
                    <td style={{ width: "0.5em" }}>:</td>
                    <td style={{ width: "100%" }}>{data.SALESMANNAME}</td>
                  </tr>
                  <tr>
                    <td className="text-nowrap">No. PO</td>
                    <td style={{ width: "0.5em" }}>:</td>
                    <td style={{ width: "100%" }}>{data.PURCHASEORDERNO}</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td className="text-center" style={{ width: "30%" }}>
              <h4>
                <strong>INVOICE</strong>
              </h4>
              <br />
              BCA BANJARMASIN
              <br />
              AN : PT BANJAR BEARING SENTOSA
              <br />
              NO : 0510585658
            </td>
            <td style={{ width: "35%" }}>
              <table className="m-0 table table-borderless table-sm">
                <tbody>
                  <tr>
                    <td className="text-nowrap">Tanggal</td>
                    <td style={{ width: "0.5em" }}>:</td>
                    <td style={{ width: "100%" }}>{data.INVOICEDATE}</td>
                  </tr>
                  <tr>
                    <td className="text-nowrap">Kepada</td>
                    <td style={{ width: "0.5em" }}>:</td>
                    <td style={{ width: "100%" }}>{}</td>
                  </tr>
                  <tr>
                    <td colSpan={3} className="text-center">
                      {data.SHIPTO1}
                      <br />
                      {data.SHIPTO2}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <table className="m-0 table table-borderless table-bordered-y table-sm">
        <thead>
          <tr>
            <th
              className="text-center border-top border-left border-dark"
              style={{ width: "1em" }}
            >
              No.
            </th>
            <th className="text-center border-top border-dark">NAMA BARANG</th>
            <th className="text-center border-top border-dark">QTY</th>
            <th className="text-center border-top border-dark">HARGA @</th>
            <th className="text-center border-top border-right border-dark">
              JUMLAH
            </th>
          </tr>
        </thead>
        <tbody>
          {(data.ITEMS || []).map((item, index) => {
            const key = `${item.ITEMNO}_${index}`;
            return (
              <tr key={key}>
                <td
                  className="text-center border-left border-dark"
                  style={{ width: "1em" }}
                >
                  {index + 1}
                </td>
                <td>{item.ITEMOVDESC}</td>
                <td className="text-center">
                  {Number.parseInt(item.QUANTITY, 10)} {item.ITEMUNIT}
                </td>
                <td className="text-center">{}</td>
                <td className="text-center border-right border-dark">{}</td>
              </tr>
            );
          })}
          <tr>
            <td className="border-top border-dark" colSpan={3}>
              {}
            </td>
            <td className="border border-dark">SUB TOTAL</td>
            <td className="border border-dark">Rp</td>
          </tr>
          <tr>
            <td colSpan={3}>
              <div className="d-flex justify-content-around">
                <span>Diterima :</span>
                <span>Diperiksa</span>
                <span>Dikirim</span>
              </div>
            </td>
            <td className="border border-dark">PPN 10%</td>
            <td className="border border-dark">Rp</td>
          </tr>
          <tr>
            <td colSpan={3}>{}</td>
            <td className="border border-dark">Total Faktur</td>
            <td className="border border-dark">Rp</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default StockOutInvoice;
