import { get, withAuth } from "./base";

const getStockIn = (id = null, startDate = null, endDate = null) => {
  const params = [];
  let queryParams = "";

  if (id) {
    params.push(`item=${id}`);
  }

  if (startDate) {
    params.push(`start_date=${startDate}`);
  }

  if (endDate) {
    params.push(`end_date=${endDate}`);
  }

  if (params.length > 0) {
    queryParams = `?${params.join("&")}`;
  }

  return get(`stockin${queryParams}`, withAuth());
};

const getStockOutInvoice = (id = null, startDate = null, endDate = null) => {
  const params = [];
  let queryParams = "";

  if (id) {
    params.push(`item=${id}`);
  }

  if (startDate) {
    params.push(`start_date=${startDate}`);
  }

  if (endDate) {
    params.push(`end_date=${endDate}`);
  }

  if (params.length > 0) {
    queryParams = `?${params.join("&")}`;
  }

  return get(`stockout-invoice${queryParams}`, withAuth());
};

const getStockOutSO = (id = null, startDate = null, endDate = null) => {
  const params = [];
  let queryParams = "";

  if (id) {
    params.push(`item=${id}`);
  }

  if (startDate) {
    params.push(`start_date=${startDate}`);
  }

  if (endDate) {
    params.push(`end_date=${endDate}`);
  }

  if (params.length > 0) {
    queryParams = `?${params.join("&")}`;
  }

  return get(`stockout-so${queryParams}`, withAuth());
};

export default { getStockIn, getStockOutInvoice, getStockOutSO };
