import { matchPath } from "react-router-dom";
import { NavTabs, TabLink } from "../../components/NavTabs";

function ListStockNavTabs() {
  return (
    <NavTabs className="rounded-top-inherit">
      <TabLink to="/list-stock" title="List Stock" />
      <TabLink
        to="/stock-template"
        title="Stock Template"
        isActive={(match, location) => {
          const childRoutes = [
            "/stock-template/edit/:id",
            "/stock-template/edit/:tid/group/edit/:id",
            "/stock-template/edit/:tid/group/new",
          ];

          if (!match) {
            return matchPath(location.pathname, {
              path: childRoutes,
              exact: true,
              strict: true,
            });
          }

          return true;
        }}
      />
    </NavTabs>
  );
}

export default ListStockNavTabs;
