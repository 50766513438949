import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { unstable_batchedUpdates as batchedUpdates } from "react-dom";
import { useHistory } from "react-router-dom";
import { debounce } from "lodash";
import MainLayout from "./layouts/MainLayout";
import ControlledTable from "../components/ControlledTable";
import SearchBar from "../components/SearchBar";
import RoundButton from "../components/RoundButton";
import Modal from "../components/Modal";
import { AddCircle, CheckCircle, Delete, Edit } from "../components/Icon";
import { user } from "../services/api";

const initialHeader = [
  {
    Header: "ID",
    accessor: "id",
    className: "text-left",
    enableRowSpan: true,
  },
  {
    Header: "Customer Name",
    accessor: "customer_name",
    className: "text-left",
    enableRowSpan: true,
    inheritId: "id",
  },
  {
    Header: "Template",
    accessor: "template_name",
    className: "text-left",
    enableRowSpan: true,
    inheritId: "id",
  },
  {
    Header: "Warehouse",
    accessor: "warehouse_name",
    className: "text-left",
  },
  {
    Header: "Last Update",
    accessor: "updated_at",
    className: "text-left",
    enableRowSpan: true,
    inheritId: "id",
  },
];

async function fetchUser(keyword, page, signal) {
  const response = await user.getSearch(keyword, page, signal);

  if (!response || (response && !response.ok)) {
    return {};
  }

  const result = await response.json();
  return result;
}

function mapToDataTable(result) {
  const mappedData = [];

  if (result && result.data && result.data.data) {
    result.data.data.forEach((customer) => {
      if (customer.warehouses.length > 0) {
        customer.warehouses.forEach((wh) => {
          mappedData.push({
            id: customer.id,
            customer_name: customer.name,
            template_name: customer.stock_template
              ? customer.stock_template.name
              : "",
            warehouse_name: wh.warehouse_name,
            updated_at: customer.updated_at,
          });
        });
      } else {
        mappedData.push({
          id: customer.id,
          customer_name: customer.name,
          template_name: customer.stock_template
            ? customer.stock_template.name
            : "",
          warehouse_name: "",
          updated_at: customer.updated_at,
        });
      }
    });
  }

  return mappedData;
}

function parsePageCount(result) {
  let pageCount = 1;
  if (result && result.data && result.data.last_page) {
    pageCount = result.data.last_page;
  }
  return pageCount;
}

function parsePageLength(result) {
  let pageLength = 0;
  if (result && result.data && result.data.data) {
    pageLength = result.data.data.length;
  }
  return pageLength;
}

function ManageCustomer() {
  const abortController = useRef();
  const modalDelete = useRef(null);

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageLength, setPageLength] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [deleteCustomerId, setDeleteCustomerId] = useState(0);
  const [deleteCustomerName, setDeleteCustomerName] = useState("");

  const headerTable = useMemo(() => initialHeader, []);

  const handleFetchData = useCallback(async () => {
    let cancelled = false;
    setLoading(true);

    if (abortController.current) {
      cancelled = true;
      abortController.current.abort();
    }

    abortController.current = new AbortController();

    const result = await fetchUser(
      searchKeyword,
      pageIndex + 1,
      abortController.current.signal
    );

    if (result.data) {
      cancelled = false;
      setDataTable(mapToDataTable(result));
      setPageCount(parsePageCount(result));
      setPageLength(parsePageLength(result));
    }

    if (!cancelled) {
      setLoading(false);
    }
  }, [searchKeyword, pageIndex]);

  const handlePageChange = useCallback(
    async ({ pageIndex: index }) => {
      setPageIndex(index);
    },
    [pageIndex]
  );

  const debouncedSearch = debounce((keyword) => {
    batchedUpdates(() => {
      setPageIndex(0);
      setSearchKeyword(keyword);
    });
  }, 500);

  function handleSearch(keyword) {
    debouncedSearch(keyword);
  }

  function handleClickNewUser() {
    history.push("/manage-customer/new");
  }

  function handleClickEdit(row) {
    history.push(`/manage-customer/edit/${row.original.id}`);
  }

  function handleClickDelete(row) {
    setDeleted(false); // reset deleted state
    setDeleteCustomerId(row.original.id);
    setDeleteCustomerName(row.original.customer_name);

    modalDelete.current.unfreeze();
    modalDelete.current.open();
  }

  async function handleClickConfirmDelete() {
    setDeleting(true);

    // prevent modal from closing on keydown escape and on click outside
    modalDelete.current.freeze();

    await user
      .deleteSingle(deleteCustomerId)
      .then(async (response) => {
        if (!response || (response && !response.ok)) {
          return;
        }

        const result = await response.json();

        if (result && result.status === "success") {
          setDeleting(false);
          setDeleted(true);

          await handleFetchData();
        }
      })
      .finally(() => {
        modalDelete.current.unfreeze();
      });
  }

  function handleClickCancelDelete() {
    modalDelete.current.close();
  }

  function handleClickSuccessDelete() {
    modalDelete.current.close();

    setDeleted(false);
  }

  useEffect(() => {
    return () => {
      if (abortController.current) {
        abortController.current.abort();
      }
    };
  }, []);

  return (
    <>
      <MainLayout headerTitle="Manage Customers">
        <div className="content-container">
          <ControlledTable
            loading={loading}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageLength={pageLength}
            onFetchData={handleFetchData}
            onPageChange={handlePageChange}
            columns={headerTable}
            hiddenColumns={["id"]}
            data={dataTable}
            headerClassName="content-gutters"
            header={
              <>
                <div className="search-bar-wrapper">
                  <SearchBar
                    placeholder="Search Customer"
                    onSearch={handleSearch}
                  />
                </div>
                <span className="table-header-divider" />
                <div className="table-header-item-wrapper">
                  <RoundButton onClick={handleClickNewUser}>
                    <AddCircle className="icon-small" /> Tambah User Baru
                  </RoundButton>
                </div>
              </>
            }
            hooks={(hooks) => {
              hooks.visibleColumns.push((visibleColumns) => [
                ...visibleColumns,
                {
                  id: "edit_action",
                  enableRowSpan: true,
                  inheritId: "id",
                  Cell: ({ row }) => (
                    <Edit
                      className="cursor-pointer"
                      onClick={() => handleClickEdit(row)}
                    />
                  ),
                },
                {
                  id: "delete_action",
                  enableRowSpan: true,
                  inheritId: "id",
                  Cell: ({ row }) => (
                    <Delete
                      className="cursor-pointer"
                      onClick={() => handleClickDelete(row)}
                    />
                  ),
                },
              ]);
            }}
          />
        </div>
      </MainLayout>
      <Modal ref={modalDelete}>
        <div className="bbs-modal-form display-flex flex-column align-items-center">
          {deleted ? (
            <>
              <div className="px-1 py-2">
                <CheckCircle className="icon-64px" />
              </div>
              <div className="px-1 py-2">
                <span>
                  Customer <strong>{deleteCustomerName}</strong> telah dihapus
                </span>
              </div>
              <div className="px-1 py-2">
                <button
                  type="button"
                  className="bbs-button-blue width-10em mx-2"
                  onClick={() => handleClickSuccessDelete()}
                >
                  OK
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="px-1 py-2">
                <Delete className="icon-64px" />
              </div>
              <div className="px-1 py-2">
                <span>Apakah anda yakin ingin menghapus Customer ini?</span>
              </div>
              <div className="px-1 py-2">
                <button
                  type="button"
                  className="bbs-button-blue width-10em mx-2"
                  onClick={() => handleClickConfirmDelete()}
                  disabled={deleting}
                >
                  Ya
                </button>
                <button
                  type="button"
                  className="bbs-button-blue inverted width-10em mx-2"
                  onClick={() => handleClickCancelDelete()}
                  disabled={deleting}
                >
                  Tidak
                </button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
}

export default ManageCustomer;
