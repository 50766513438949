import { useHistory } from "react-router-dom";
import SidebarMenu from "./SidebarMenu";
import { Exit } from "../../components/Icon";
import user from "../../utils/user";

function MainLayout({ children, headerTitle }) {
  const history = useHistory();

  function handleClickLogout() {
    user.removeLogin();

    history.push("/login");
  }

  return (
    <div className="page-wrapper">
      <nav>
        <SidebarMenu />
      </nav>
      <section>
        <div className="page-header-wrapper">
          <h4>{headerTitle}</h4>
          <span className="page-header-divider" />
          <button
            className="bbs-button-gray"
            type="button"
            onClick={handleClickLogout}
          >
            <Exit className="icon-small" /> Logout
          </button>
        </div>
        <div className="page-content-wrapper">{children}</div>
      </section>
    </div>
  );
}

export default MainLayout;
