import { useCallback, useEffect, useRef, useState } from "react";
import { unstable_batchedUpdates as batchedUpdates } from "react-dom";
import { debounce } from "lodash";
import MainLayout from "./layouts/MainLayout";
import ListStockNavTabs from "./layouts/ListStockNavTabs";
import ControlledTable from "../components/ControlledTable";
import SearchBar from "../components/SearchBar";
import NoDataMessage from "../components/NoDataMessage";
import { stockWarehouseAdmin } from "../services/api";

const initialHeader = [
  { Header: "No", accessor: "no" },
  {
    Header: "BRAND + Part Number",
    accessor: "name_part",
    className: "text-left",
  },
];

async function fetchStockWarehouseAdmin(keyword = "", page = 1, signal) {
  const response = await stockWarehouseAdmin.getSearch(keyword, page, signal);

  if (!response || (response && !response.ok)) {
    return {};
  }

  const result = await response.json();
  return result;
}

function mapToDataTable(result, pageIndex, pageSize) {
  let mappedData = [];

  if (result && result.data && result.data.data) {
    mappedData = result.data.data.map((item, index) => {
      const warehouses = {};
      item.warehouses.forEach((w, i) => {
        warehouses[`warehouse_${i}`] = w.quantity;
      });
      return {
        no: pageIndex * pageSize + index + 1,
        name_part: item.name_part,
        ...warehouses,
      };
    });
  }

  return mappedData;
}

function mapToHeaderTable(result) {
  const mappedData = [];

  if (result && result.data && result.data.data) {
    if (result.data.data.length > 0) {
      result.data.data[0].warehouses.forEach((w, i) => {
        mappedData.push({
          Header: w.warehouse_name,
          accessor: `warehouse_${i}`,
        });
      });
    }
  }
  return mappedData;
}

function parsePageCount(result) {
  let pageCount = 1;
  if (result && result.data && result.data.last_page) {
    pageCount = result.data.last_page;
  }
  return pageCount;
}

function ListStock() {
  const abortController = useRef();

  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [pageIndex, setPageIndex] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [dataTable, setDataTable] = useState([]);
  const [headerTable, setHeaderTable] = useState(initialHeader);

  const handleFetchData = useCallback(async () => {
    let cancelled = false;
    setLoading(true);

    if (abortController.current) {
      cancelled = true;
      abortController.current.abort();
    }

    abortController.current = new AbortController();

    const result = await fetchStockWarehouseAdmin(
      searchKeyword,
      pageIndex + 1,
      abortController.current.signal
    );

    if (result.data) {
      cancelled = false;
      setDataTable(mapToDataTable(result, pageIndex, 10));
      setPageCount(parsePageCount(result));
      setHeaderTable([...initialHeader, ...mapToHeaderTable(result)]);
    }

    if (!cancelled) {
      setLoading(false);
    }
  }, [searchKeyword, pageIndex]);

  const handlePageChange = useCallback(
    async ({ pageIndex: index }) => {
      setPageIndex(index);
    },
    [pageIndex]
  );

  const debouncedSearch = debounce((keyword) => {
    batchedUpdates(() => {
      setPageIndex(0);
      setSearchKeyword(keyword);
    });
  }, 500);

  function handleSearch(keyword) {
    debouncedSearch(keyword);
  }

  useEffect(() => {
    return () => {
      if (abortController.current) {
        abortController.current.abort();
      }
    };
  }, []);

  return (
    <MainLayout headerTitle="Welcome">
      <div className="content-container">
        <ListStockNavTabs />
        <ControlledTable
          loading={loading}
          pageCount={pageCount}
          pageIndex={pageIndex}
          onFetchData={handleFetchData}
          onPageChange={handlePageChange}
          columns={headerTable}
          data={dataTable}
          headerClassName="content-gutters"
          header={
            <>
              <div className="search-bar-wrapper">
                <SearchBar
                  placeholder="Search BRAND + Part Number"
                  onSearch={handleSearch}
                />
              </div>
            </>
          }
          noDataMessage={<NoDataMessage />}
        />
      </div>
    </MainLayout>
  );
}

export default ListStock;
