import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import toast from "cogo-toast";
import MainLayout from "./layouts/MainLayout";
import AsyncDropdownSearchable from "../components/AsyncDropdownSearchable";
import PrimaryButton from "../components/PrimaryButton";
import { Add, Dash } from "../components/Icon";
import { stockTemplate, user, warehouse } from "../services/api";

async function fetchCustomer(id) {
  const response = await user.getSingle(id);

  if (!response || (response && !response.ok)) {
    return {};
  }

  const result = await response.json();
  return result;
}

async function fetchTemplate(keyword = "") {
  const response = await stockTemplate.getSearch(keyword);

  if (!response || (response && !response.ok)) {
    return {};
  }

  const result = await response.json();
  return result;
}

function mapToDropdownTemplate(result) {
  const mappedData = [];

  if (result && result.data && result.data.data) {
    result.data.data.forEach((item) => {
      mappedData.push({
        key: item.id,
        label: item.name,
      });
    });
  }

  return mappedData;
}

async function fetchWarehouse(keyword = "") {
  const response = await warehouse.getSearch(keyword);

  if (!response || (response && !response.ok)) {
    return {};
  }

  const result = await response.json();
  return result;
}

function mapToDropdownWarehouse(result) {
  const mappedData = [];

  if (result && result.data) {
    result.data.forEach((item) => {
      mappedData.push({
        key: item.warehouse_id,
        label: item.warehouse_name,
      });
    });
  }

  return mappedData;
}

function filterSelectedWarehouse(items, selectedItems) {
  let filteredData = [];

  if (items) {
    filteredData = items.filter((item) => {
      return !selectedItems.includes(item.key);
    });
  }

  return filteredData;
}

function parseInitialData(result) {
  let initialData = {
    email: "",
    name: "",
    stock_template_id: null,
    stock_template_name: "",
    warehouses: [],
  };

  if (result && result.data) {
    initialData = {
      email: result.data.email,
      name: result.data.name,
      stock_template_id: result.data.stock_template_id,
      stock_template_name: result.data.stock_template
        ? result.data.stock_template.name
        : "",
      warehouses: result.data.warehouses,
    };
  }

  return initialData;
}

function ManageCustomerEdit() {
  const history = useHistory();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [customerData, setCustomerData] = useState({
    email: "",
    name: "",
    stock_template_id: null,
    stock_template_name: "",
  });
  const [warehouses, setWarehouses] = useState([
    {
      id: 1,
      warehouse_id: null,
      warehouse_name: "",
    },
  ]);
  const [submitted, setSubmitted] = useState(false);
  const [passwordData, setPasswordData] = useState({
    new_password: "",
    confirm_new_password: "",
  });
  const [submittedPassword, setSubmittedPassword] = useState(false);

  async function initCustomer() {
    setLoading(true);
    const result = await fetchCustomer(id);
    const parsedData = parseInitialData(result);

    const { warehouses: initWarehouses, ...initCustomerData } = parsedData;

    setWarehouses(initWarehouses);
    setCustomerData(initCustomerData);
    setLoading(false);
  }

  async function templateOptions(keyword) {
    const result = await fetchTemplate(keyword);

    return mapToDropdownTemplate(result);
  }

  async function warehouseOptions(keyword) {
    const result = await fetchWarehouse(keyword);

    const selectedItems = warehouses.map((item) => {
      return item.warehouse_id;
    });

    return filterSelectedWarehouse(
      mapToDropdownWarehouse(result),
      selectedItems
    );
  }

  function handleChangeCustomerData(event, key) {
    event.preventDefault();

    setCustomerData((prevData) => {
      return {
        ...prevData,
        [key]: event.target.value,
      };
    });
  }

  function handleChangeTemplate(option) {
    setCustomerData((prevData) => {
      return {
        ...prevData,
        stock_template_id: option.key,
      };
    });
  }

  function handleChangeWarehouse(option, item) {
    setWarehouses((prevItems) =>
      prevItems.map((prevItem) => {
        if (prevItem.id === item.id) {
          return {
            ...prevItem,
            warehouse_id: option.key,
          };
        }

        return prevItem;
      })
    );
  }

  function handleClickAddWarehouse() {
    setSubmitted(false);
    setWarehouses((prevItems) => {
      const newItem = {
        id: prevItems.length > 0 ? prevItems[prevItems.length - 1].id + 1 : 1,
        warehouse_id: null,
        warehouse_name: "",
      };

      return [...prevItems, newItem];
    });
  }

  function handleClickDeleteWarehouse(item) {
    setWarehouses((prevItems) =>
      prevItems.filter((prevItem) => {
        return prevItem.id !== item.id;
      })
    );
  }

  function buildPayload() {
    return {
      name: customerData.name,
      stock_template_id: customerData.stock_template_id,
      user_warehouses: warehouses.map((item) => {
        return item.warehouse_id;
      }),
    };
  }

  const inputIsFilled = useCallback(() => {
    return (
      warehouses.every((item) => {
        return item.warehouse_id !== null;
      }) &&
      customerData.email !== "" &&
      customerData.password !== "" &&
      customerData.confirm_password !== "" &&
      customerData.name !== "" &&
      customerData.stock_template_id != null
    );
  }, [warehouses, customerData]);

  async function handleClickSave() {
    setSubmitted(true);
    if (inputIsFilled()) {
      await user.updateSingle(id, { json: buildPayload() }).then((response) => {
        if (response && response.status === 200) {
          toast.success("data berhasil diedit.");
          history.push(`/manage-customer`);
        }
      });
    } else {
      toast.warn("item tidak boleh ada yang kosong.");
    }
    setSubmitted(false);
  }

  function handleChangePasswordData(event, key) {
    event.preventDefault();

    setPasswordData((prevData) => {
      return {
        ...prevData,
        [key]: event.target.value,
      };
    });
  }

  const passwordIsValid = useCallback(() => {
    return (
      passwordData.new_password !== "" &&
      passwordData.confirm_new_password !== "" &&
      passwordData.new_password === passwordData.confirm_new_password
    );
  }, [passwordData]);

  async function handleClickSavePassword() {
    setSubmittedPassword(true);

    if (passwordIsValid()) {
      await user.changePassword(id, { json: passwordData }).then((response) => {
        if (response && response.status === 200) {
          toast.success("password berhasil diganti.");
          history.push(`/manage-customer`);
        }
      });
    } else {
      toast.warn("password baru tidak valid.");
    }

    setSubmittedPassword(false);
  }

  useEffect(async () => {
    await initCustomer();
  }, []);

  return (
    <MainLayout headerTitle="Manage Customers">
      <div className="d-flex">
        <div className="content-container m-auto width-35em">
          <Tabs
            defaultIndex={0}
            selectedTabClassName="active"
            className="rounded-top-inherit overflow-hidden"
          >
            <TabList className="nav nav-tabs nav-fill bbs-nav-tabs">
              <Tab className="nav-item nav-link cursor-pointer">Edit User</Tab>
              <Tab className="nav-item nav-link cursor-pointer">
                Ganti Password
              </Tab>
            </TabList>
            <div className="position-relative" style={{ paddingTop: 0 }}>
              {loading ? (
                <div className="bg-white-alpha-50 position-absolute w-100 h-100 d-flex align-items-center justify-content-center z-index-1">
                  <div className="spinner-border text-primary" role="status" />
                </div>
              ) : null}
              <div className="content-gutters-large">
                <TabPanel>
                  <form>
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input
                        readOnly
                        className="form-control"
                        id="email"
                        value={customerData.email}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="customer-name">Nama Customer</label>
                      <input
                        className={`form-control ${
                          submitted && !customerData.name && "is-invalid"
                        }`}
                        id="customer-name"
                        onChange={(event) =>
                          handleChangeCustomerData(event, "name")
                        }
                        value={customerData.name}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="template">Template</label>
                      <AsyncDropdownSearchable
                        id="template"
                        toggleClassName={`form-control bg-transparent input-icon-right input-icon-small icon-drop ${
                          submitted &&
                          !customerData.stock_template_id &&
                          "is-invalid"
                        }`}
                        dropdownMenuClassName="w-100"
                        searchbarClassName="w-100"
                        searchbarPlaceholder="Search Template"
                        loadItems={templateOptions}
                        onChange={handleChangeTemplate}
                        defaultValue={customerData.stock_template_name}
                      />
                    </div>
                    {warehouses.map((item) => (
                      <div className="form-group row" key={item.id}>
                        <div className="col">
                          <label htmlFor={`warehouse_${item.id}`}>
                            Warehouse
                          </label>
                          <AsyncDropdownSearchable
                            id={`warehouse_${item.id}`}
                            toggleClassName={`form-control bg-transparent input-icon-right input-icon-small icon-drop ${
                              submitted &&
                              item.warehouse_id === null &&
                              "is-invalid"
                            }`}
                            dropdownMenuClassName="w-100"
                            searchbarClassName="w-100"
                            searchbarPlaceholder="Search Warehouse"
                            loadItems={warehouseOptions}
                            onChange={(value) =>
                              handleChangeWarehouse(value, item)
                            }
                            defaultValue={item.warehouse_name}
                          />
                        </div>
                        <div className="align-self-end py-1">
                          <Dash
                            className="red-circle cursor-pointer"
                            onClick={() => handleClickDeleteWarehouse(item)}
                          />
                        </div>
                        <span className="mr-3" />
                      </div>
                    ))}
                    <div className="form-group">
                      <PrimaryButton onClick={handleClickAddWarehouse}>
                        <Add className="icon-16px" /> Add Warehouse
                      </PrimaryButton>
                    </div>
                    <div className="form-group mt-5">
                      <PrimaryButton
                        fullWidth
                        onClick={handleClickSave}
                        disabled={submitted}
                      >
                        Save
                      </PrimaryButton>
                    </div>
                  </form>
                </TabPanel>
                <TabPanel>
                  <form>
                    <div className="form-group">
                      <label htmlFor="new-password">Password baru</label>
                      <input
                        type="password"
                        className="form-control"
                        id="new-password"
                        onChange={(event) =>
                          handleChangePasswordData(event, "new_password")
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="confirm-new-password">
                        Ketik ulang password baru
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="confirm-new-password"
                        onChange={(event) =>
                          handleChangePasswordData(
                            event,
                            "confirm_new_password"
                          )
                        }
                      />
                    </div>
                    <div className="form-group mt-5">
                      <PrimaryButton
                        fullWidth
                        onClick={handleClickSavePassword}
                        disabled={submittedPassword}
                      >
                        Submit
                      </PrimaryButton>
                    </div>
                  </form>
                </TabPanel>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </MainLayout>
  );
}

export default ManageCustomerEdit;
