import { NavLink } from "react-router-dom";

function MenuItem({ children, isActive, linkTo, show }) {
  if (show) {
    return (
      <NavLink
        activeClassName="sidebar-menu-item-active"
        className="sidebar-menu-item text-bbs-primary"
        isActive={isActive}
        to={linkTo}
      >
        {children}
      </NavLink>
    );
  }

  return null;
}

export default MenuItem;
