import {
  useEffect,
  useImperativeHandle,
  useState,
  forwardRef,
  useCallback,
} from "react";
import { createPortal } from "react-dom";

const modalElement = document.getElementById("modal-root");

function Modal(
  {
    children,
    defaultOpened = false,
    closeOnClickOutside: coco = true,
    closeOnEscape: coe = true,
  },
  ref
) {
  const [show, setShow] = useState(defaultOpened);
  const [closeOnClickOutside, setCloseOnClickOutside] = useState(coco);
  const [closeOnEscape, setCloseOnEscape] = useState(coe);

  const open = useCallback(() => setShow(true), []);
  const close = useCallback(() => setShow(false), []);

  const freeze = useCallback(() => {
    setCloseOnClickOutside(false);
    setCloseOnEscape(false);
  }, []);

  const unfreeze = useCallback(() => {
    setCloseOnClickOutside(true);
    setCloseOnEscape(true);
  }, []);

  const handleKeyDownEscape = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        close();
      }
    },
    [close]
  );

  useImperativeHandle(
    ref,
    () => ({
      open,
      close,
      freeze,
      unfreeze,
    }),
    [open, close, freeze, unfreeze]
  );

  useEffect(() => {
    if (closeOnEscape && show) {
      document.addEventListener("keydown", handleKeyDownEscape, false);
    } else {
      document.removeEventListener("keydown", handleKeyDownEscape, false);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDownEscape, false);
    };
  }, [handleKeyDownEscape, closeOnEscape, show]);

  const handleClickOutside = useCallback(() => {
    if (closeOnClickOutside) {
      close();
    }
  }, [close, closeOnClickOutside]);

  return createPortal(
    show ? (
      <div className="bbs-modal">
        <div
          className="bbs-modal-overlay"
          onClick={handleClickOutside}
          aria-hidden="true"
        />
        <div className="bbs-modal-body">{children}</div>
      </div>
    ) : null,
    modalElement
  );
}

export default forwardRef(Modal);
