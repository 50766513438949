import { Empty } from "./Icon";

function NoDataMessage() {
  return (
    <div className="text-center">
      <div className="p-3">
        <Empty />
      </div>
      <strong>
        BRAND + Part Number Tidak Ditemukan, Silahkan Hubungi Kami (0811504844)
      </strong>
    </div>
  );
}

export default NoDataMessage;
