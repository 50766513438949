import { get, withAuth } from "./base";

const getSearch = (keyword = "", page = 1, date = null, signal) => {
  const params = [];
  let queryParams = "";

  if (keyword) {
    params.push(`search=${keyword}`);
  }

  if (page > 0) {
    params.push(`page=${page}`);
  } else {
    params.push("page=1");
  }

  if (date !== null) {
    params.push(`date=${date}`);
  }

  if (params.length > 0) {
    queryParams = `?${params.join("&")}`;
  }

  return get(`purchase-order${queryParams}`, withAuth({ signal }));
};

export default { getSearch };
