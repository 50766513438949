function HrDashed() {
  return (
    <hr
      style={{
        borderColor: "#000000",
        borderStyle: "dashed",
        borderWidth: 1,
        margin: 0,
      }}
    />
  );
}

function Form({ data = {} }) {
  return (
    <div className="border mb-2">
      <table style={{ height: "1px" }} className="table table-borderless">
        <tbody>
          <tr>
            <td className="h-100" style={{ width: "50%" }}>
              <div className="border border-dark rounded h-100">
                <div className="px-3 py-2">
                  <strong>PT. BANJAR BEARING SENTOSA</strong>
                </div>
                <HrDashed />
                <div className="px-3 py-2">
                  Jl. Gatot Subroto No. 74 RT.032 <br />
                  Kuripan - Banjarmasin Timur <br />
                  Kota Banjarmasin Kalimantan Selatan
                </div>
              </div>
            </td>
            <td className="h-100" style={{ width: "50%" }}>
              <div className="border border-dark rounded h-100">
                <h3 className="px-3 py-2 my-2">
                  <strong>ITEM TRANSFER</strong>
                </h3>
                <HrDashed />
                <div className="px-3 py-2">
                  <span>
                    Transfer No: <strong>{data.TRANSFERNO}</strong>
                  </span>
                  <br />
                  <span>
                    Transfer Date: <strong>{data.TRANSFERDATE}</strong>
                  </span>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td className="h-100" style={{ width: "50%" }}>
              <div className="border border-dark rounded h-100 px-3 py-2">
                Transfer From
                <br />
                <strong>{data.FNAME}</strong>
              </div>
            </td>
            <td className="h-100" style={{ width: "50%" }}>
              <div className="border border-dark rounded h-100 px-3 py-2">
                Transfer To
                <br />
                <strong>{data.TNAME}</strong>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div style={{ padding: "0.75rem" }}>
        <div className="table-rounded-container">
          <table className="m-0 table table-borderless table-bordered-y">
            <thead>
              <tr>
                <th className="text-center">Item</th>
                <th className="text-center">Item Description</th>
                <th className="text-center" style={{ maxWidth: "30px" }}>
                  Quantity
                </th>
                <th className="text-center" style={{ maxWidth: "30px" }}>
                  Unit
                </th>
              </tr>
            </thead>
            <tbody>
              {(data.ITEMS || []).map((item, index) => {
                const key = `${item.ITEMNO}_${index}`;
                return (
                  <tr key={key}>
                    <td>{item.ITEMNO}</td>
                    <td>{item.ITEMDESCRIPTION}</td>
                    <td className="text-center">{item.QUANTITY}</td>
                    <td className="text-center">{}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <table style={{ height: "1px" }} className="table table-borderless">
        <tbody>
          <tr>
            <td>
              Prepared By <br />
              <br />
              <br />
              <hr />
              Date
            </td>
            <td>
              Approved By <br />
              <br />
              <br />
              <hr />
              Date
            </td>
            <td>
              Transferred By <br />
              <br />
              <br />
              <hr />
              Date
            </td>
            <td>
              Received By <br />
              <br />
              <br />
              <hr />
              Date
            </td>
            <td className="h-100">
              <div
                className="h-100 border border-dark rounded position-relative"
                style={{ minWidth: "300px" }}
              >
                <span
                  className="position-absolute bg-white px-1 mx-2"
                  style={{ top: "-0.9em" }}
                >
                  Description
                </span>
                <div className="px-3 py-2">
                  <strong>{data.DESCRIPTION}</strong>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Form;
