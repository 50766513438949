import { get, post, put, del, withAuth } from "./base";

const getSearch = (keyword = "", page = 1, signal) => {
  return get(`users?page=${page}&name=${keyword}`, withAuth({ signal }));
};

const getSingle = (id) => {
  return get(`user/${id}`, withAuth());
};

const changePassword = (id, options) => {
  return post(`auth/change-password/${id}`, withAuth(options));
};

const createSingle = (options) => {
  return post("auth/register", withAuth(options));
};

const updateSingle = (id, options) => {
  return put(`user/${id}`, withAuth(options));
};

const deleteSingle = (id) => {
  return del(`user/${id}`, withAuth());
};

export default {
  getSearch,
  getSingle,
  changePassword,
  createSingle,
  updateSingle,
  deleteSingle,
};
