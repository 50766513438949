import { Redirect, Route } from "react-router-dom";
import user from "../utils/user";

function PrivateRoute({ children, adminOnly, ...props }) {
  function isAuthorized() {
    if (!user.hasAccessToken()) {
      return <Redirect to="/login" />;
    }

    if (adminOnly && !user.isAdmin()) {
      return <Redirect to="/stock-customer" />;
    }

    return children;
  }

  return <Route {...props} render={() => isAuthorized()} />;
}

export default PrivateRoute;
