import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { unstable_batchedUpdates as batchedUpdates } from "react-dom";
import { useHistory } from "react-router-dom";
import { debounce } from "lodash";
import toast from "cogo-toast";
import MainLayout from "./layouts/MainLayout";
import ListStockNavTabs from "./layouts/ListStockNavTabs";
import ControlledTable from "../components/ControlledTable";
import SearchBar from "../components/SearchBar";
import PrimaryButton from "../components/PrimaryButton";
import Modal from "../components/Modal";
import { Add, CheckCircle, Delete, EyeOpen } from "../components/Icon";
import { stockTemplate } from "../services/api";

const initialHeader = [
  { Header: "No", accessor: "no" },
  {
    Header: "Nama Template",
    accessor: "name",
    className: "text-left",
  },
];

async function fetchStockTemplate(keyword, page, signal) {
  const response = await stockTemplate.getSearch(keyword, page, signal);

  if (!response || (response && !response.ok)) {
    return {};
  }

  const result = await response.json();
  return result;
}

function mapToDataTable(result, pageIndex, pageSize) {
  const mappedData = [];

  if (result && result.data && result.data.data) {
    result.data.data.forEach((item, index) => {
      mappedData.push({
        no: pageIndex * pageSize + index + 1,
        id: item.id,
        name: item.name,
      });
    });
  }

  return mappedData;
}

function parsePageCount(result) {
  let pageCount = 1;
  if (result && result.data && result.data.last_page) {
    pageCount = result.data.last_page;
  }
  return pageCount;
}

function StockTemplate() {
  const abortController = useRef();
  const modalAdd = useRef(null);
  const modalDelete = useRef(null);

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [pageIndex, setPageIndex] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [addTemplatePayload, setAddTemplatePayload] = useState({ name: "" });
  const [deleteTemplateId, setDeleteTemplateId] = useState(0);
  const [deleteTemplateName, setDeleteTemplateName] = useState("");

  const headerTable = useMemo(() => initialHeader, []);

  const handleFetchData = useCallback(async () => {
    let cancelled = false;
    setLoading(true);

    if (abortController.current) {
      cancelled = true;
      abortController.current.abort();
    }

    abortController.current = new AbortController();

    const result = await fetchStockTemplate(
      searchKeyword,
      pageIndex + 1,
      abortController.current.signal
    );

    if (result.data) {
      cancelled = false;
      setDataTable(mapToDataTable(result, pageIndex, 10));
      setPageCount(parsePageCount(result));
    }

    if (!cancelled) {
      setLoading(false);
    }
  }, [searchKeyword, pageIndex]);

  const handlePageChange = useCallback(
    async ({ pageIndex: index }) => {
      setPageIndex(index);
    },
    [pageIndex]
  );

  const debouncedSearch = debounce((keyword) => {
    batchedUpdates(() => {
      setPageIndex(0);
      setSearchKeyword(keyword);
    });
  }, 500);

  function handleSearch(keyword) {
    debouncedSearch(keyword);
  }

  function handleClickAddTemplate() {
    setAddTemplatePayload({ name: "" });
    modalAdd.current.open();
  }

  function handleChangeTemplateName(event) {
    setAddTemplatePayload({ name: event.target.value });
  }

  async function handleClickAddTemplateCreate() {
    await stockTemplate
      .createSingle({ json: addTemplatePayload })
      .then(async (response) => {
        if (!response || (response && !response.ok)) {
          return;
        }

        const result = await response.json();

        if (result && result.status === "success") {
          await handleFetchData();
        }
      })
      .finally(() => {
        modalAdd.current.close();
      });
  }

  function handleClickEdit(row) {
    history.push(`/stock-template/edit/${row.original.id}`);
  }

  function handleClickDelete(row) {
    setDeleted(false); // reset deleted state
    setDeleteTemplateId(row.original.id);
    setDeleteTemplateName(row.original.name);

    modalDelete.current.unfreeze();
    modalDelete.current.open();
  }

  async function handleClickConfirmDelete() {
    setDeleting(true);

    // prevent modal from closing on keydown escape and on click outside
    modalDelete.current.freeze();

    await stockTemplate
      .deleteSingle(deleteTemplateId)
      .then(async (response) => {
        const result = await response.json();

        if (result && result.status === "error") {
          setDeleting(false);
          toast.warn(result.message);
        }

        if (result && result.status === "success") {
          setDeleting(false);
          setDeleted(true);

          await handleFetchData();
        }
      })
      .finally(() => {
        modalDelete.current.unfreeze();
      });
  }

  function handleClickCancelDelete() {
    modalDelete.current.close();
  }

  function handleClickSuccessDelete() {
    modalDelete.current.close();

    setDeleted(false);
  }

  useEffect(() => {
    return () => {
      if (abortController.current) {
        abortController.current.abort();
      }
    };
  }, []);

  return (
    <>
      <MainLayout headerTitle="Welcome">
        <div className="content-container">
          <ListStockNavTabs />
          <ControlledTable
            loading={loading}
            pageCount={pageCount}
            pageIndex={pageIndex}
            onFetchData={handleFetchData}
            onPageChange={handlePageChange}
            columns={headerTable}
            data={dataTable}
            headerClassName="content-gutters"
            header={
              <>
                <div className="search-bar-wrapper">
                  <SearchBar
                    placeholder="Search Nama Template"
                    onSearch={handleSearch}
                  />
                </div>
                <span className="table-header-divider" />
                <PrimaryButton onClick={handleClickAddTemplate}>
                  <Add className="icon-16px" /> Add Template
                </PrimaryButton>
              </>
            }
            hooks={(hooks) => {
              hooks.visibleColumns.push((visibleColumns) => [
                ...visibleColumns,
                {
                  id: "edit_action",
                  Cell: ({ row }) => (
                    <EyeOpen
                      className="cursor-pointer"
                      onClick={() => handleClickEdit(row)}
                    />
                  ),
                },
                {
                  id: "delete_action",
                  Cell: ({ row }) => (
                    <Delete
                      className="cursor-pointer"
                      onClick={() => handleClickDelete(row)}
                    />
                  ),
                },
              ]);
            }}
          />
        </div>
      </MainLayout>
      <Modal ref={modalAdd}>
        <div className="bbs-modal-form">
          <div className="bbs-modal-form-item">
            <h4 className="bbs-modal-form-title">Buat Template</h4>
          </div>
          <div className="bbs-modal-form-item">
            <label htmlFor="template-name" className="form-label">
              Nama Template
              <input
                className="form-control display-inline-block"
                id="template-name"
                value={addTemplatePayload.name}
                onChange={handleChangeTemplateName}
              />
            </label>
          </div>
          <div className="bbs-modal-form-item">
            <PrimaryButton
              fullWidth
              onClick={handleClickAddTemplateCreate}
              disabled={addTemplatePayload.name === ""}
            >
              Create
            </PrimaryButton>
          </div>
        </div>
      </Modal>
      <Modal ref={modalDelete}>
        <div className="bbs-modal-form display-flex flex-column align-items-center">
          {deleted ? (
            <>
              <div className="px-1 py-2">
                <CheckCircle className="icon-64px" />
              </div>
              <div className="px-1 py-2">
                <span>
                  Template <strong>{deleteTemplateName}</strong> telah dihapus
                </span>
              </div>
              <div className="px-1 py-2">
                <button
                  type="button"
                  className="bbs-button-blue width-10em mx-2"
                  onClick={() => handleClickSuccessDelete()}
                >
                  OK
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="px-1 py-2">
                <Delete className="icon-64px" />
              </div>
              <div className="px-1 py-2">
                <span>Apakah anda yakin ingin menghapus Template ini?</span>
              </div>
              <div className="px-1 py-2">
                <button
                  type="button"
                  className="bbs-button-blue width-10em mx-2"
                  onClick={() => handleClickConfirmDelete()}
                  disabled={deleting}
                >
                  Ya
                </button>
                <button
                  type="button"
                  className="bbs-button-blue inverted width-10em mx-2"
                  onClick={() => handleClickCancelDelete()}
                  disabled={deleting}
                >
                  Tidak
                </button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
}

export default StockTemplate;
