import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import MainLayout from "./layouts/MainLayout";
import ControlledTable from "../components/ControlledTable";
import { orderAcknowledge } from "../services/api";

const initialHeader = [
  { Header: "No.", accessor: "no" },
  {
    Header: "Supplier Item ID",
    accessor: "supplier_item_id",
    className: "text-left",
  },
  {
    Header: "Description Item",
    accessor: "item_description",
    className: "text-left",
  },
  { Header: "Order Quantity", accessor: "order_quantity" },
  { Header: "Extended Price", accessor: "extended_price" },
  { Header: "Back Order Quantity", accessor: "back_order_quantity" },
  {
    Header: "Seller Order Document ID",
    accessor: "seller_order_document_id",
    className: "text-left",
  },
  {
    Header: "Seller Order Document Date",
    accessor: "seller_order_document_date",
  },
  {
    Header: "Purchase Order Document Date",
    accessor: "purchase_order_document_date",
  },
  { Header: "Package Code", accessor: "package_code" },
  { Header: "Amount", accessor: "amount" },
  { Header: "Per Quantity", accessor: "per_quantity" },
  { Header: "Country of Manufacture", accessor: "country_of_manufacture" },
  { Header: "Order Line Status", accessor: "order_line_status" },
  { Header: "Need Delivery Date", accessor: "need_delivery_date" },
  { Header: "Promise Delivery Date", accessor: "promise_delivery_date" },
  { Header: "Pack Quantity", accessor: "pack_quantity" },
  {
    Header: "Original Order Quantity",
    accessor: "original_order_quantity",
  },
  { Header: "Accepted Quantity", accessor: "accepted_quantity" },
];

async function fetchOrderAcknowledge(page, signal) {
  const response = await orderAcknowledge.getAll(page, signal);

  if (!response || (response && !response.ok)) {
    return {};
  }

  const result = await response.json();
  return result;
}

function mapToDataTable(result, pageIndex, pageSize) {
  const mappedData = [];

  if (result && result.data && result.data.data) {
    result.data.data.forEach((item, index) => {
      mappedData.push({
        no: pageIndex * pageSize + index + 1,
        supplier_item_id: item.suplier_item_id,
        item_description: item.description_item,
        order_quantity: item.order_quantity,
        extended_price: item.extended_price,
        back_order_quantity: item.back_order_quantity,
        seller_order_document_id: item.seller_order_document_id,
        seller_order_document_date: item.seller_order_document_date,
        purchase_order_document_date: item.purchase_order_document_date,
        package_code: item.package_code,
        amount: item.amount,
        per_quantity: item.per_quantity,
        country_of_manufacture: item.country_of_manufacture,
        order_line_status: item.order_line_status,
        need_delivery_date: item.need_delivery_date,
        promise_delivery_date: item.promise_delivery_date,
        pack_quantity: item.pack_quantity,
        original_order_quantity: item.original_order_quantity,
        accepted_quantity: item.accepted_quantity,
      });
    });
  }

  return mappedData;
}

function parsePageCount(result) {
  let pageCount = 1;
  if (result && result.data && result.data.last_page) {
    pageCount = result.data.last_page;
  }
  return pageCount;
}

function parsePageLength(result) {
  let pageLength = 0;
  if (result && result.data && result.data.data) {
    pageLength = result.data.data.length;
  }
  return pageLength;
}

function OrderAcknowledge() {
  const abortController = useRef();

  const [loading, setLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageLength, setPageLength] = useState(0);

  const headerTable = useMemo(() => initialHeader, []);

  const handleFetchData = useCallback(async () => {
    let cancelled = false;
    setLoading(true);

    if (abortController.current) {
      cancelled = true;
      abortController.current.abort();
    }

    abortController.current = new AbortController();

    const result = await fetchOrderAcknowledge(
      pageIndex + 1,
      abortController.current.signal
    );

    if (result.data) {
      cancelled = false;
      setDataTable(mapToDataTable(result, pageIndex, 10));
      setPageCount(parsePageCount(result));
      setPageLength(parsePageLength(result));
    }

    if (!cancelled) {
      setLoading(false);
    }
  }, [pageIndex]);

  const handlePageChange = useCallback(
    async ({ pageIndex: index }) => {
      setPageIndex(index);
    },
    [pageIndex]
  );

  useEffect(() => {
    return () => {
      if (abortController.current) {
        abortController.current.abort();
      }
    };
  }, []);

  return (
    <MainLayout headerTitle="Welcome">
      <div className="content-container">
        <ControlledTable
          loading={loading}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageLength={pageLength}
          onFetchData={handleFetchData}
          onPageChange={handlePageChange}
          columns={headerTable}
          data={dataTable}
        />
      </div>
    </MainLayout>
  );
}

export default OrderAcknowledge;
