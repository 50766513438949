function SearchBar({ placeholder, onSearch, value, defaultValue, className }) {
  function handleChange(event) {
    event.preventDefault();

    if (onSearch) {
      onSearch(event.target.value);
    }
  }

  return (
    <input
      className={`search-bar ${className}`}
      type="text"
      placeholder={placeholder}
      onChange={handleChange}
      value={value}
      defaultValue={defaultValue}
    />
  );
}

export default SearchBar;
