import { useCallback, useEffect, useRef, useState } from "react";
import { unstable_batchedUpdates as batchedUpdates } from "react-dom";
import { Link } from "react-router-dom";
import { debounce } from "lodash";
import { format, isBefore, isDate, parse } from "date-fns";
import toast from "cogo-toast";
import MainLayout from "./layouts/MainLayout";
import ControlledTable from "../components/ControlledTable";
import SearchBar from "../components/SearchBar";
import DatePicker from "../components/DatePicker";
import DropdownButton from "../components/DropdownButton";
import { Export, RectangleGreen, RectangleRed } from "../components/Icon";
import { stockCustomer } from "../services/api";
import { download } from "../utils/download";
import userLogin from "../utils/user";

const WAREHOUSE_CENTRE_ID = 0;

const initialHeader = [
  {
    Header: "No",
    accessor: "no",
    enableRowSpan: true,
    inheritId: "id",
  },
  {
    Header: "ID",
    accessor: "id",
    className: "text-left",
    enableRowSpan: true,
  },
  {
    Header: "Nama Grup",
    accessor: "group_name",
    className: "text-left",
    enableRowSpan: true,
    inheritId: "id",
  },
  {
    Header: "Items",
    accessor: "item_name",
    className: "text-left",
  },
  {
    Header: "BRAND + Part Number",
    accessor: "brand_part_number",
    className: "text-left",
  },
  {
    Header: "Terpasang",
    accessor: "installed_quantity",
  },
  {
    Header: "Stock Minimum",
    accessor: "minimum_stock_quantity",
  },
  {
    Header: "Stock In (Refill)",
    accessor: "stockin",
  },
  {
    Header: "Terpakai (Belum PO)",
    accessor: "stockout_so",
  },
  {
    Header: "Terfaktur (Sudah PO)",
    accessor: "stockout_inv",
  },
];

function dateFormatter(date) {
  if (isDate(date)) {
    return format(date, "M/d/Y");
  }

  return null;
}

function dateParser(date) {
  return parse(date, "M/d/y", new Date());
}

async function fetchStockCustomer(id, page, anotherParams, signal) {
  const response = await stockCustomer.getSingle(
    id,
    page,
    anotherParams,
    signal
  );

  if (!response || (response && !response.ok)) {
    return {};
  }

  const result = await response.json();
  return result;
}

async function fetchDownload(id, startDate, endDate) {
  const response = await stockCustomer.getDownload(id, startDate, endDate);

  if (!response || (response && !response.ok)) {
    toast.warn("export failed.");
    return;
  }

  download(response.body, response.headers);
}

function mapToDataTable(result, pageIndex, pageSize) {
  const mappedData = [];

  if (result && result.data && result.data.data) {
    result.data.data.forEach((group, index) => {
      if (group.stock_template_group_items.length > 0) {
        group.stock_template_group_items.forEach((item) => {
          const stockWarehouses = {};

          item.warehouses.forEach((warehouse, i) => {
            if (warehouse.id !== WAREHOUSE_CENTRE_ID) {
              stockWarehouses[`stock_warehouse_${i}`] = warehouse.stock;
            }
          });

          mappedData.push({
            no: pageIndex * pageSize + index + 1,
            id: group.id,
            group_name: group.name,
            item_name: item.name,
            brand_part_number_id: item.brand_part_number_id,
            brand_part_number: item.brand_part_number,
            installed_quantity: item.installed_quantity,
            minimum_stock_quantity: item.minimum_stock_quantity,
            stockin: item.stockin || 0,
            stockout_so: item.stockout_so || 0,
            stockout_inv: item.stockout_inv || 0,
            ...stockWarehouses,
          });
        });
      } else {
        mappedData.push({
          no: pageIndex * pageSize + index + 1,
          id: group.id,
          group_name: group.name,
          item_name: "",
          brand_part_number_id: "",
          brand_part_number: "",
          installed_quantity: "",
          minimum_stock_quantity: "",
          stockin: "",
          stockout_so: "",
          stockout_inv: "",
        });
      }
    });
  }

  return mappedData;
}

function mapToHeaderTable(result) {
  const mappedData = [];

  if (result && result.data && result.data.data) {
    if (result.data.data.length > 0) {
      const [group] = result.data.data;

      if (group.stock_template_group_items.length > 0) {
        const [item] = group.stock_template_group_items;

        item.warehouses.forEach((warehouse, i) => {
          if (warehouse.id !== WAREHOUSE_CENTRE_ID) {
            mappedData.push({
              Header: `Stock ${warehouse.warehouse.NAME}`,
              accessor: `stock_warehouse_${i}`,
            });
          }
        });
      }
    }
  }

  return mappedData;
}

function parsePageCount(result) {
  let pageCount = 1;
  if (result && result.data && result.data.last_page) {
    pageCount = result.data.last_page;
  }
  return pageCount;
}

function parsePageLength(result) {
  let pageLength = 0;
  if (result && result.data && result.data.data) {
    pageLength = result.data.data.length;
  }
  return pageLength;
}

function StockCustomerForUser() {
  const abortController = useRef();

  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageLength, setPageLength] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchBy, setSearchBy] = useState({ key: "item", label: "Items" });
  const [selectedCustomer, setSelectedCustomer] = useState({
    id: null,
    name: "",
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [headerTable, setHeaderTable] = useState(initialHeader);

  const handleFetchData = useCallback(async () => {
    let cancelled = false;
    setLoading(true);

    if (abortController.current) {
      cancelled = true;
      abortController.current.abort();
    }

    abortController.current = new AbortController();

    if (selectedCustomer.id !== null) {
      const anotherParams = [{ key: searchBy.key, value: searchKeyword }];

      if (startDate !== null) {
        anotherParams.push({ key: "start_date", value: startDate });
      }

      if (endDate !== null) {
        anotherParams.push({ key: "end_date", value: endDate });
      }

      const result = await fetchStockCustomer(
        selectedCustomer.id,
        pageIndex + 1,
        anotherParams,
        abortController.current.signal
      );

      if (result.data) {
        cancelled = false;
        setDataTable(mapToDataTable(result, pageIndex, 5));
        setHeaderTable([...initialHeader, ...mapToHeaderTable(result)]);
        setPageCount(parsePageCount(result));
        setPageLength(parsePageLength(result));
      }
    }

    if (!cancelled) {
      setLoading(false);
    }
  }, [selectedCustomer, searchKeyword, startDate, endDate, pageIndex]);

  const handlePageChange = useCallback(
    async ({ pageIndex: index }) => {
      setPageIndex(index);
    },
    [pageIndex]
  );

  const debouncedSearch = debounce((keyword) => {
    batchedUpdates(() => {
      setPageIndex(0);
      setSearchKeyword(keyword);
    });
  }, 500);

  function handleSearch(keyword) {
    debouncedSearch(keyword);
  }

  function handleChangeSearchBy(option) {
    setSearchBy(option);
  }

  function handleDatePickStart(datePicked) {
    setStartDate(dateFormatter(datePicked));
  }

  function handleDatePickEnd(datePicked) {
    setEndDate(dateFormatter(datePicked));
  }

  async function handleClickExport() {
    if (startDate === null || endDate === null) {
      toast.warn("filter rentang tanggal tidak boleh kosong.");
      return;
    }

    if (isBefore(dateParser(endDate), dateParser(startDate))) {
      toast.warn("filter rentang tanggal tidak valid.");
      return;
    }

    setDownloading(true);
    if (selectedCustomer.id !== null) {
      await fetchDownload(selectedCustomer.id, startDate, endDate);
    }
    setDownloading(false);
  }

  useEffect(() => {
    if (selectedCustomer.id === null) {
      setSelectedCustomer({
        id: userLogin.getLoginId(),
        name: userLogin.getLoginName(),
      });
    }

    return () => {
      if (abortController.current) {
        abortController.current.abort();
      }
    };
  }, []);

  return (
    <>
      <MainLayout headerTitle="Welcome">
        <div className="content-container">
          <ControlledTable
            loading={loading}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageLength={pageLength}
            onFetchData={handleFetchData}
            onPageChange={handlePageChange}
            columns={headerTable}
            hiddenColumns={["id"]}
            data={dataTable}
            headerClassName="content-gutters align-items-stretch flex-wrap"
            header={
              <>
                <div className="align-self-end">
                  <div className="search-bar-wrapper">
                    <SearchBar
                      placeholder={`Search ${searchBy.label}`}
                      onSearch={handleSearch}
                    />
                    <span className="table-header-item-text" />
                    <DropdownButton
                      className="bbs-button-primary button-icon-right button-icon-small button-drop-white"
                      items={[
                        { key: "item", label: "Items" },
                        { key: "group", label: "Nama Grup" },
                        {
                          key: "brand",
                          label: "BRAND + Part Number",
                        },
                      ]}
                      onChange={handleChangeSearchBy}
                    />
                  </div>
                </div>
                <span className="table-header-divider" />
                <div className="display-flex flex-column">
                  <div className="display-flex align-items-end">
                    <div className="date-picker-wrapper">
                      <span>Filter Date</span>
                      <DatePicker
                        onDatePick={handleDatePickStart}
                        dateFormat="dd MMMM y"
                      />
                    </div>
                    <span className="table-header-item-text">to</span>
                    <div className="date-picker-wrapper">
                      <DatePicker
                        onDatePick={handleDatePickEnd}
                        dateFormat="dd MMMM y"
                      />
                    </div>
                  </div>
                  <div className="display-flex justify-content-end mt-auto p-2">
                    <button
                      disabled={downloading}
                      className="bbs-button-export"
                      type="button"
                      onClick={handleClickExport}
                    >
                      <Export className="align-bottom" />
                      {downloading ? "Downloading..." : "Export table to Excel"}
                    </button>
                  </div>
                </div>
              </>
            }
            footer={
              <div>
                <div className="d-flex">
                  <div className="p-2">
                    <div className="p-1">
                      <RectangleGreen /> <span>Stock Customer tersedia</span>
                    </div>
                    <div className="p-1">
                      <RectangleRed />{" "}
                      <span>Stock Customer tidak mencukupi</span>
                    </div>
                  </div>
                </div>
              </div>
            }
            getCellProps={(cell) => {
              if (
                cell &&
                cell.column &&
                cell.column.id &&
                cell.row &&
                cell.row.values &&
                cell.row.values.minimum_stock_quantity !== null &&
                cell.row.values.minimum_stock_quantity !== ""
              ) {
                const { id } = cell.column;
                const { minimum_stock_quantity: minimum } = cell.row.values;

                if (id.indexOf("stock_warehouse") !== -1) {
                  if (cell.value < minimum) {
                    return {
                      style: {
                        color: "#E6362D",
                      },
                    };
                  }

                  return {
                    style: {
                      color: "#3CDD14",
                    },
                  };
                }
              }

              return {};
            }}
            hooks={(hooks) => {
              const startDateEncoded = encodeURIComponent(startDate);
              const endDateEncoded = encodeURIComponent(endDate);
              hooks.visibleColumns.push((visibleColumns) => {
                return visibleColumns.map((column) => {
                  if (column.id === "stockin") {
                    return {
                      ...column,
                      Cell: ({ row, value }) => {
                        const idEncoded = encodeURIComponent(
                          row.original.brand_part_number_id
                        );

                        const queryParams = `?item=${idEncoded}&start_date=${startDateEncoded}&end_date=${endDateEncoded}`;

                        return (
                          <Link to={`/stock-in${queryParams}`} target="_blank">
                            {value}
                          </Link>
                        );
                      },
                    };
                  }

                  if (column.id === "stockout_so") {
                    return {
                      ...column,
                      Cell: ({ row, value }) => {
                        const idEncoded = encodeURIComponent(
                          row.original.brand_part_number_id
                        );

                        const queryParams = `?item=${idEncoded}&start_date=${startDateEncoded}&end_date=${endDateEncoded}`;

                        return (
                          <Link
                            to={`/stock-out-so${queryParams}`}
                            target="_blank"
                          >
                            {value}
                          </Link>
                        );
                      },
                    };
                  }

                  if (column.id === "stockout_inv") {
                    return {
                      ...column,
                      Cell: ({ row, value }) => {
                        const idEncoded = encodeURIComponent(
                          row.original.brand_part_number_id
                        );

                        const queryParams = `?item=${idEncoded}&start_date=${startDateEncoded}&end_date=${endDateEncoded}`;

                        return (
                          <Link
                            to={`/stock-out-inv${queryParams}`}
                            target="_blank"
                          >
                            {value}
                          </Link>
                        );
                      },
                    };
                  }

                  return column;
                });
              });
            }}
          />
        </div>
      </MainLayout>
    </>
  );
}

export default StockCustomerForUser;
