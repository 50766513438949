import { saveAs } from "file-saver";
import { parse } from "content-disposition";

function streamToBlob(stream) {
  const response = new Response(stream);
  return response.blob();
}

function readStream(readableStream) {
  const reader = readableStream.getReader();

  return new ReadableStream({
    start(controller) {
      function pump() {
        return reader.read().then(function processChunk({ done, value }) {
          if (done) {
            controller.close();
            return undefined;
          }

          controller.enqueue(value);
          return pump();
        });
      }

      return pump();
    },
  });
}

// eslint-disable-next-line import/prefer-default-export
export async function download(body, headers) {
  const response = new Response(body, { headers });
  const stream = readStream(response.body);
  const blob = await streamToBlob(stream);

  let fileName = "download.xlsx";

  if (response.headers.has("Content-Disposition")) {
    const { parameters } = parse(response.headers.get("Content-Disposition"));
    if (parameters && parameters.filename) {
      fileName = parameters.filename;
    }
  }

  saveAs(blob, fileName);
}
