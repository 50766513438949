function PrimaryButton({
  children,
  onClick,
  fullWidth = false,
  className = "",
  disabled = false,
}) {
  return (
    <button
      className={`bbs-button-blue text-nowrap ${
        fullWidth ? "w-100" : ""
      } ${className}`}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export default PrimaryButton;
