function getAccessToken() {
  return localStorage.getItem("access_token");
}

function getLoginId() {
  return localStorage.getItem("user.id");
}

function getLoginName() {
  return localStorage.getItem("user.name");
}

function hasAccessToken() {
  return localStorage.getItem("access_token") !== null;
}

function isAdmin() {
  return localStorage.getItem("user.role.name") === "admin";
}

function isBranch() {
  return localStorage.getItem("user.role.name") === "branch";
}

function isDistributor() {
  return localStorage.getItem("user.role.name") === "distributor";
}

function isUser() {
  return localStorage.getItem("user.role.name") === "user";
}

function removeLogin() {
  localStorage.removeItem("access_token");
  localStorage.removeItem("user.id");
  localStorage.removeItem("user.name");
  localStorage.removeItem("user.role.name");
}

function setLogin(result) {
  localStorage.setItem("access_token", result.token);
  localStorage.setItem("user.id", result.user.id);
  localStorage.setItem("user.name", result.user.name);
  localStorage.setItem("user.role.name", result.user.role.name);
}

export default {
  getAccessToken,
  getLoginId,
  getLoginName,
  hasAccessToken,
  isAdmin,
  isBranch,
  isDistributor,
  isUser,
  removeLogin,
  setLogin,
};
