import { useEffect, useState } from "react";
import { format, isDate, parseISO } from "date-fns";
import { id as idLocale } from "date-fns/locale";
import { stockInOut } from "../../../services/api";
import { getQueryParams } from "../../../utils/common";
import Form from "./Form";

async function fetchStockOutSO(id, startDate, endDate) {
  const response = await stockInOut.getStockOutSO(id, startDate, endDate);

  if (!response || (response && !response.ok)) {
    return {};
  }

  const result = await response.json();
  return result;
}

function dateFormatter(date) {
  const dateFormat = "dd MMM y";

  if (isDate(date)) {
    return format(date, dateFormat, {
      locale: idLocale,
    });
  }

  return "";
}

function mapToFormData(result) {
  const mappedData = [];

  if (result && result.data) {
    result.data.forEach((item) => {
      mappedData.push({
        ...item,
        invoicedate: dateFormatter(parseISO(item.invoicedate)),
      });
    });
  }

  return mappedData;
}

function StockOutSO() {
  const queryParams = getQueryParams();
  const id = queryParams.get("item");
  const startDate = queryParams.get("start_date");
  const endDate = queryParams.get("end_date");

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState([]);

  async function initFetchStockOutSO() {
    setLoading(true);
    const result = await fetchStockOutSO(id, startDate, endDate);

    setFormData(mapToFormData(result));
    setLoading(false);
  }

  useEffect(() => {
    initFetchStockOutSO();
  }, []);
  return (
    <div
      className="bg-white p-2 pb-5 m-auto position-relative"
      style={{ fontFamily: "IBM Plex Sans", maxWidth: "297mm" }}
    >
      {loading ? (
        <div className="z-index-1 bg-white-alpha-50 position-absolute w-100 h-100 d-flex align-items-center justify-content-center">
          <div className="spinner-border text-primary" role="status" />
        </div>
      ) : null}
      {!loading && formData.length < 1 ? (
        <>
          <div className="z-index-1 bg-white-alpha-50 position-absolute w-100 h-100 d-flex align-items-center justify-content-center">
            <span className="font-weight-bold" style={{ fontSize: "8em" }}>
              NO DATA
            </span>
          </div>
          <Form />
        </>
      ) : null}
      {formData.map((data) => {
        return <Form key={data.SOID} data={data} />;
      })}
    </div>
  );
}

export default StockOutSO;
