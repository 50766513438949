import { NavLink } from "react-router-dom";

function NavTabs({ children, className }) {
  return (
    <nav className={`nav nav-tabs nav-fill bbs-nav-tabs ${className}`}>
      {children}
    </nav>
  );
}

function TabLink({ to, title, exact, isActive }) {
  return (
    <NavLink
      activeClassName="active"
      className="nav-item nav-link"
      to={to}
      exact={exact}
      isActive={isActive}
    >
      {title}
    </NavLink>
  );
}

export { NavTabs, TabLink };
