import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { unstable_batchedUpdates as batchedUpdates } from "react-dom";
import { debounce } from "lodash";
import { format, isDate } from "date-fns";
import MainLayout from "./layouts/MainLayout";
import ControlledTable from "../components/ControlledTable";
import SearchBar from "../components/SearchBar";
import DatePicker from "../components/DatePicker";
import { purchaseOrder } from "../services/api";

const initialDate = new Date();

const initialHeader = [
  { Header: "No", accessor: "no" },
  { Header: "Item No", accessor: "itemno" },
  { Header: "Items", accessor: "item", className: "text-left" },
  { Header: "Tanggal", accessor: "date" },
];

function dateFormatter(date) {
  if (isDate(date)) {
    return format(date, "M/d/Y");
  }

  return null;
}

async function fetchPurchaseOrder(keyword, page, date, signal) {
  const response = await purchaseOrder.getSearch(keyword, page, date, signal);

  if (!response || (response && !response.ok)) {
    return {};
  }

  const result = await response.json();
  return result;
}

function mapToDataTable(result, pageIndex, pageSize) {
  const mappedData = [];

  if (result && result.data && result.data.data) {
    result.data.data.forEach((item, index) => {
      mappedData.push({
        no: pageIndex * pageSize + index + 1,
        itemno: item.itemno,
        item: item.item,
        date: item.date,
      });
    });
  }

  return mappedData;
}

function parsePageCount(result) {
  let pageCount = 1;
  if (result && result.data && result.data.last_page) {
    pageCount = result.data.last_page;
  }
  return pageCount;
}

function parsePageLength(result) {
  let pageLength = 0;
  if (result && result.data && result.data.data) {
    pageLength = result.data.data.length;
  }
  return pageLength;
}

function PurchaseOrder() {
  const abortController = useRef();

  const [loading, setLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageLength, setPageLength] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [date, setDate] = useState(null);

  const headerTable = useMemo(() => initialHeader, []);

  const handleFetchData = useCallback(async () => {
    let cancelled = false;
    setLoading(true);

    if (abortController.current) {
      cancelled = true;
      abortController.current.abort();
    }

    abortController.current = new AbortController();

    if (date !== null) {
      const result = await fetchPurchaseOrder(
        searchKeyword,
        pageIndex + 1,
        date,
        abortController.current.signal
      );

      if (result.data) {
        cancelled = false;
        setDataTable(mapToDataTable(result, pageIndex, 10));
        setPageCount(parsePageCount(result));
        setPageLength(parsePageLength(result));
      }
    }

    if (!cancelled) {
      setLoading(false);
    }
  }, [searchKeyword, date, pageIndex]);

  const handlePageChange = useCallback(
    async ({ pageIndex: index }) => {
      setPageIndex(index);
    },
    [pageIndex]
  );

  const debouncedSearch = debounce((keyword) => {
    batchedUpdates(() => {
      setPageIndex(0);
      setSearchKeyword(keyword);
    });
  }, 500);

  function handleSearch(keyword) {
    debouncedSearch(keyword);
  }

  function handleDatePick(datePicked) {
    setDate(dateFormatter(datePicked));
  }

  useEffect(() => {
    return () => {
      if (abortController.current) {
        abortController.current.abort();
      }
    };
  }, []);

  return (
    <MainLayout headerTitle="Welcome">
      <div className="content-container">
        <ControlledTable
          loading={loading}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageLength={pageLength}
          onFetchData={handleFetchData}
          onPageChange={handlePageChange}
          columns={headerTable}
          hiddenColumns={["itemno"]}
          data={dataTable}
          headerClassName="content-gutters"
          header={
            <>
              <div className="search-bar-wrapper">
                <SearchBar
                  placeholder="Search Part Number ID"
                  onSearch={handleSearch}
                />
              </div>
              <span className="table-header-divider" />
              <span className="table-header-item-text font-weight-bold">
                Filter Date
              </span>
              <div className="date-picker-wrapper">
                <DatePicker
                  defaultValue={initialDate}
                  onDatePick={handleDatePick}
                />
              </div>
            </>
          }
        />
      </div>
    </MainLayout>
  );
}

export default PurchaseOrder;
