import { useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import SearchBar from "./SearchBar";

function AsyncDropdownSearchable({
  loadItems,
  toggleClassName,
  togglePlaceholder,
  searchbarClassName,
  searchbarPlaceholder,
  dropdownMenuClassName,
  onChange,
  htmlFor,
  id,
  defaultValue,
}) {
  const nodeRef = useRef();

  const [show, setShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  async function getItems(keyword = "") {
    setLoading(true);
    const result = await loadItems(keyword);
    setItems(result);
    if (result) {
      setLoading(false);
    }
  }

  const debouncedGetItems = debounce(getItems, 500);

  function toggleDropdown() {
    setShow(!show);
  }

  function handleClickOutside(event) {
    if (nodeRef.current.contains(event.target)) {
      return;
    }

    setShow(false);
  }

  function handleClickDropdownItem(item) {
    if (onChange) {
      onChange(item);
    }

    setSelectedItem(item.label);
    setShow(false);
  }

  function handleSearch(keyword = "") {
    debouncedGetItems(keyword);
  }

  useEffect(() => {
    if (show) {
      getItems();
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]);

  useEffect(() => {
    if (defaultValue) {
      setSelectedItem(defaultValue);
    }
  }, [defaultValue]);

  return (
    <div ref={nodeRef} className="dropdown">
      <input
        className={toggleClassName}
        placeholder={togglePlaceholder}
        onClick={toggleDropdown}
        value={selectedItem}
        htmlFor={htmlFor}
        id={id}
        readOnly
      />
      <ul
        className={`dropdown-menu ${
          show ? "show" : ""
        } ${dropdownMenuClassName}`}
      >
        <li>
          <div className="dropdown-item-text">
            <SearchBar
              className={searchbarClassName}
              placeholder={searchbarPlaceholder}
              onSearch={handleSearch}
            />
          </div>
        </li>
        <li>
          <ul className="scrollable-menu">
            {loading ? (
              <li>
                <div className="dropdown-item" aria-hidden="true">
                  Loading...
                </div>
              </li>
            ) : (
              items.map((item) => (
                <li key={item.key} title={item.label}>
                  <div
                    className="dropdown-item text-truncate"
                    onClick={() => handleClickDropdownItem(item)}
                    aria-hidden="true"
                  >
                    {item.label}
                  </div>
                </li>
              ))
            )}
          </ul>
        </li>
      </ul>
    </div>
  );
}

export default AsyncDropdownSearchable;
