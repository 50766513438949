import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import {
  ListStock,
  ListStockCentre,
  Login,
  ManageCustomer,
  ManageCustomerNew,
  ManageCustomerEdit,
  OrderAcknowledge,
  PurchaseOrder,
  StockCustomer,
  StockCustomerForUser,
  StockTemplate,
  StockTemplateEdit,
  StockTemplateGroupEdit,
  StockTemplateGroupNew,
} from "./pages";
import { StockIn, StockOutInvoice, StockOutSO } from "./pages/stock-forms";
import user from "./utils/user";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <PrivateRoute path="/list-stock">
          {user.isAdmin() ? <ListStock /> : null}
          {user.isBranch() || user.isDistributor() ? <ListStockCentre /> : null}
        </PrivateRoute>
        <PrivateRoute adminOnly path="/stock-template/edit/:tid/group/edit/:id">
          <StockTemplateGroupEdit />
        </PrivateRoute>
        <PrivateRoute adminOnly path="/stock-template/edit/:tid/group/new">
          <StockTemplateGroupNew />
        </PrivateRoute>
        <PrivateRoute adminOnly path="/stock-template/edit/:id">
          <StockTemplateEdit />
        </PrivateRoute>
        <PrivateRoute adminOnly path="/stock-template">
          <StockTemplate />
        </PrivateRoute>
        <PrivateRoute path="/stock-customer">
          {user.isAdmin() ? <StockCustomer /> : null}
          {user.isUser() ? <StockCustomerForUser /> : null}
        </PrivateRoute>
        <PrivateRoute adminOnly path="/manage-customer/new">
          <ManageCustomerNew />
        </PrivateRoute>
        <PrivateRoute adminOnly path="/manage-customer/edit/:id">
          <ManageCustomerEdit />
        </PrivateRoute>
        <PrivateRoute adminOnly path="/manage-customer">
          <ManageCustomer />
        </PrivateRoute>
        <PrivateRoute adminOnly path="/order-acknowledge">
          <OrderAcknowledge />
        </PrivateRoute>
        <PrivateRoute adminOnly path="/purchase-order">
          <PurchaseOrder />
        </PrivateRoute>
        <PrivateRoute path="/stock-in">
          <StockIn />
        </PrivateRoute>
        <PrivateRoute path="/stock-out-inv">
          <StockOutInvoice />
        </PrivateRoute>
        <PrivateRoute path="/stock-out-so">
          <StockOutSO />
        </PrivateRoute>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
