import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import toast from "cogo-toast";
import MainLayout from "./layouts/MainLayout";
import AsyncDropdownSearchable from "../components/AsyncDropdownSearchable";
import PrimaryButton from "../components/PrimaryButton";
import { Add, Dash } from "../components/Icon";
import { brandPartNumber, stockTemplateGroup } from "../services/api";

async function fetchPartNumber(keyword = "", signal) {
  const response = await brandPartNumber.getSearch(keyword, signal);

  if (!response || (response && !response.ok)) {
    return {};
  }

  const result = await response.json();
  return result;
}

async function fetchStockTemplateGroup(id) {
  const response = await stockTemplateGroup.getSingle(id);

  if (!response || (response && !response.ok)) {
    return {};
  }

  const result = await response.json();
  return result;
}

function mapToDropdownItem(result) {
  const mappedData = [];

  if (result && result.data) {
    result.data.forEach((item) => {
      mappedData.push({
        key: item.id,
        label: item.name,
      });
    });
  }

  return mappedData;
}

function filterSelectedItem(items, selectedItems) {
  let filteredData = [];

  if (items) {
    filteredData = items.filter((item) => {
      return !selectedItems.includes(item.key);
    });
  }

  return filteredData;
}

function parseItems(result) {
  let items = [];

  if (result && result.data && result.data.stock_template_group_items) {
    items = result.data.stock_template_group_items.map((item, i) => {
      return {
        id: i + 1,
        name: item.name,
        brand_part_number_id: item.brand_part_number_id,
        brand_part_number: item.brand_part_number,
        minimum_stock_quantity: item.minimum_stock_quantity.toString(),
        installed_quantity: item.installed_quantity.toString(),
      };
    });
  }
  return items;
}

function parseGroupData(result) {
  const groupData = { name: "", stock_template_id: 0 };

  if (result && result.data) {
    groupData.name = result.data.name;
    groupData.stock_template_id = result.data.stock_template_id;
  }

  return groupData;
}

function StockTemplateGroupEdit() {
  const abortController = useRef();

  const history = useHistory();
  const { tid, id } = useParams();

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([
    {
      id: 1,
      installed_quantity: "0",
      minimum_stock_quantity: "0",
      name: "",
      brand_part_number_id: null,
      brand_part_number: null,
    },
  ]);
  const [groupData, setGroupData] = useState({ name: "" });
  const [submitted, setSubmitted] = useState(false);

  async function initStockTemplateGroup() {
    setLoading(true);
    const result = await fetchStockTemplateGroup(id);

    const parsedItems = parseItems(result);

    setGroupData(parseGroupData(result));
    setItems(parsedItems);
    setLoading(false);
  }

  async function dropdownOptions(keyword) {
    if (abortController.current) {
      abortController.current.abort();
    }

    abortController.current = new AbortController();

    const result = await fetchPartNumber(
      keyword,
      abortController.current.signal
    );

    const selectedItems = items.map((item) => {
      if (item.brand_part_number_id) {
        return item.brand_part_number_id;
      }

      return null;
    });

    if (result.data) {
      return filterSelectedItem(mapToDropdownItem(result), selectedItems);
    }

    return false;
  }

  function handleClickAddItem() {
    setSubmitted(false);
    setItems((prevItems) => {
      const newItem = {
        id: prevItems.length > 0 ? prevItems[prevItems.length - 1].id + 1 : 1,
        installed_quantity: "0",
        minimum_stock_quantity: "0",
        name: "",
        brand_part_number_id: null,
        brand_part_number: null,
      };

      return [...prevItems, newItem];
    });
  }

  function handleClickDeleteItem(item) {
    setItems((prevItems) =>
      prevItems.filter((prevItem) => {
        return prevItem.id !== item.id;
      })
    );
  }

  function handleChangeInputText(event, item, key) {
    event.preventDefault();

    setItems((prevItems) =>
      prevItems.map((prevItem) => {
        if (prevItem.id === item.id) {
          return {
            ...prevItem,
            [key]: event.target.value,
          };
        }

        return prevItem;
      })
    );
  }

  function handleChangeGroupName(event) {
    event.preventDefault();

    setGroupData({ ...groupData, name: event.target.value });
  }

  function handleChangeBrandPartNumber(option, item) {
    setItems((prevItems) =>
      prevItems.map((prevItem) => {
        if (prevItem.id === item.id) {
          return {
            ...prevItem,
            brand_part_number_id: option.key,
            brand_part_number: option.label,
          };
        }

        return prevItem;
      })
    );
  }

  function buildPayload() {
    return {
      name: groupData.name,
      stock_template_id: tid,
      items,
    };
  }

  const inputIsFilled = useCallback(() => {
    return (
      items.every((item) => {
        return (
          item.name !== "" &&
          item.brand_part_number_id &&
          item.brand_part_number &&
          item.installed_quantity &&
          item.minimum_stock_quantity
        );
      }) && groupData.name
    );
  }, [items, groupData]);

  async function handleClickSave() {
    setSubmitted(true);
    if (inputIsFilled()) {
      await stockTemplateGroup
        .updateSingle(id, {
          json: buildPayload(),
        })
        .then((response) => {
          if (response && response.status === 200) {
            toast.success("data berhasil diedit.");
            history.push(`/stock-template/edit/${tid}`);
          }
        });
    } else {
      toast.warn("item tidak boleh ada yang kosong.");
    }
    setSubmitted(false);
  }

  useEffect(async () => {
    await initStockTemplateGroup();

    return () => {
      if (abortController.current) {
        abortController.current.abort();
      }
    };
  }, []);

  return (
    <MainLayout>
      <div className="d-flex">
        <div className="content-container content-gutters-large m-auto width-80em">
          <div className="position-relative">
            {loading ? (
              <div className="bg-white-alpha-50 position-absolute w-100 h-100 d-flex align-items-center justify-content-center z-index-1">
                <div className="spinner-border text-primary" role="status" />
              </div>
            ) : null}
            <form>
              <h4 className="font-weight-bold mb-4">Group</h4>
              <div className="row">
                <div className="col-md-4 form-group">
                  <label htmlFor="group-name">Nama Grup</label>
                  <input
                    className={`form-control ${
                      submitted && !groupData.name && "is-invalid"
                    }`}
                    id="group-name"
                    onChange={handleChangeGroupName}
                    value={groupData.name}
                  />
                </div>
              </div>
              {items.map((item) => (
                <div className="row align-items-baseline" key={item.id}>
                  <div className="col-md-4 form-group">
                    <label htmlFor={`name_${item.id}`}>Nama Item</label>
                    <input
                      className={`form-control ${
                        submitted && !item.name && "is-invalid"
                      }`}
                      id={`name_${item.id}`}
                      onChange={(event) =>
                        handleChangeInputText(event, item, "name")
                      }
                      value={item.name}
                    />
                  </div>
                  <div className="col-md-4 form-group">
                    <label htmlFor={`brand-part-number_${item.id}`}>
                      BRAND + Part Number
                    </label>
                    <AsyncDropdownSearchable
                      id={`brand-part-number_${item.id}`}
                      toggleClassName={`form-control bg-transparent input-icon-right input-icon-small icon-drop ${
                        submitted && !item.brand_part_number_id && "is-invalid"
                      }`}
                      dropdownMenuClassName="w-100"
                      searchbarClassName="w-100"
                      searchbarPlaceholder="Search BRAND + Part Number"
                      loadItems={dropdownOptions}
                      onChange={(value) =>
                        handleChangeBrandPartNumber(value, item)
                      }
                      defaultValue={item.brand_part_number}
                    />
                  </div>
                  <div className="col form-group">
                    <label htmlFor={`minimum-stock-quantity_${item.id}`}>
                      Min. Stock
                    </label>
                    <input
                      type="number"
                      className={`form-control ${
                        submitted &&
                        !item.minimum_stock_quantity &&
                        "is-invalid"
                      }`}
                      id={`minimum-stock-quantity_${item.id}`}
                      onChange={(event) =>
                        handleChangeInputText(
                          event,
                          item,
                          "minimum_stock_quantity"
                        )
                      }
                      value={item.minimum_stock_quantity}
                    />
                  </div>
                  <div className="col form-group">
                    <label htmlFor={`installed-quantity_${item.id}`}>
                      Terpasang
                    </label>
                    <input
                      type="number"
                      className={`form-control ${
                        submitted && !item.installed_quantity && "is-invalid"
                      }`}
                      id={`installed-quantity_${item.id}`}
                      onChange={(event) =>
                        handleChangeInputText(event, item, "installed_quantity")
                      }
                      value={item.installed_quantity}
                    />
                  </div>
                  <div className="col form-group d-flex justify-content-center align-self-end p-1">
                    <Dash
                      className="red-circle cursor-pointer"
                      onClick={() => handleClickDeleteItem(item)}
                    />
                  </div>
                </div>
              ))}
              <div className="form-group mt-3">
                <PrimaryButton onClick={handleClickAddItem}>
                  <Add className="icon-16px" /> Add Item
                </PrimaryButton>
              </div>
              <div className="d-flex justify-content-center mt-5">
                <PrimaryButton
                  className="width-20em"
                  onClick={handleClickSave}
                  disabled={submitted}
                >
                  Save
                </PrimaryButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default StockTemplateGroupEdit;
