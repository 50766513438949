import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { unstable_batchedUpdates as batchedUpdates } from "react-dom";
import { Link, useHistory, useParams } from "react-router-dom";
import { debounce } from "lodash";
import MainLayout from "./layouts/MainLayout";
import ListStockNavTabs from "./layouts/ListStockNavTabs";
import ControlledTable from "../components/ControlledTable";
import SearchBar from "../components/SearchBar";
import DropdownButton from "../components/DropdownButton";
import PrimaryButton from "../components/PrimaryButton";
import Modal from "../components/Modal";
import { Add, BackArrow, CheckCircle, Delete, Edit } from "../components/Icon";
import { stockTemplate, stockTemplateGroup } from "../services/api";

const initialHeader = [
  {
    Header: "No",
    accessor: "no",
    enableRowSpan: true,
    inheritId: "id",
  },
  {
    Header: "ID",
    accessor: "id",
    className: "text-left",
    enableRowSpan: true,
  },
  {
    Header: "Nama Grup",
    accessor: "group_name",
    className: "text-left",
    enableRowSpan: true,
    inheritId: "id",
  },
  {
    Header: "Items",
    accessor: "item_name",
    className: "text-left",
  },
  {
    Header: "BRAND + Part Number",
    accessor: "brand_part_number",
    className: "text-left",
  },
  {
    Header: "Terpasang",
    accessor: "installed_quantity",
  },
  {
    Header: "Stock Minimum",
    accessor: "minimum_stock_quantity",
  },
];

async function fetchStockTemplate(id, page, anotherParams, signal) {
  const response = await stockTemplate.getSingle(
    id,
    page,
    anotherParams,
    signal
  );

  if (!response || (response && !response.ok)) {
    return {};
  }

  const result = await response.json();
  return result;
}

function mapToDataTable(result, pageIndex, pageSize) {
  const mappedData = [];

  if (
    result &&
    result.data &&
    result.data.data &&
    result.data.data.stock_template_groups
  ) {
    result.data.data.stock_template_groups.forEach((group, index) => {
      if (group.stock_template_group_items.length > 0) {
        group.stock_template_group_items.forEach((item) => {
          mappedData.push({
            no: pageIndex * pageSize + index + 1,
            id: group.id,
            group_name: group.name,
            item_name: item.name,
            brand_part_number_id: item.brand_part_number_id,
            brand_part_number: item.brand_part_number,
            installed_quantity: item.installed_quantity,
            minimum_stock_quantity: item.minimum_stock_quantity,
          });
        });
      } else {
        mappedData.push({
          no: pageIndex * pageSize + index + 1,
          id: group.id,
          group_name: group.name,
          item_name: "",
          brand_part_number_id: "",
          brand_part_number: "",
          installed_quantity: "",
          minimum_stock_quantity: "",
        });
      }
    });
  }

  return mappedData;
}

function parseInitialData(result) {
  const initialData = { name: "" };

  if (result && result.data && result.data.data) {
    initialData.name = result.data.data.name;
  }

  return initialData;
}

function parsePageCount(result) {
  let pageCount = 1;
  if (result && result.data && result.data.last_page) {
    pageCount = result.data.last_page;
  }
  return pageCount;
}

function parsePageLength(result) {
  let pageLength = 0;
  if (
    result &&
    result.data &&
    result.data.data &&
    result.data.data.stock_template_groups
  ) {
    pageLength = result.data.data.stock_template_groups.length;
  }
  return pageLength;
}

function StockTemplateEdit() {
  const abortController = useRef();
  const modalDelete = useRef(null);
  const modalEdit = useRef(null);

  const history = useHistory();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [saving, setSaving] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [initialData, setInitialData] = useState({ name: "" });
  const [dataTable, setDataTable] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageLength, setPageLength] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchBy, setSearchBy] = useState({ key: "item", label: "Items" });
  const [templateData, setTemplateData] = useState({ name: "" });
  const [deleteGroupId, setDeleteGroupId] = useState(0);
  const [deleteGroupName, setDeleteGroupName] = useState("");

  const headerTable = useMemo(() => initialHeader, []);

  const handleFetchData = useCallback(async () => {
    let cancelled = false;
    setLoading(true);

    if (abortController.current) {
      cancelled = true;
      abortController.current.abort();
    }

    abortController.current = new AbortController();

    if (id !== null) {
      const anotherParams = [{ key: searchBy.key, value: searchKeyword }];

      const result = await fetchStockTemplate(
        id,
        pageIndex + 1,
        anotherParams,
        abortController.current.signal
      );

      if (result.data) {
        cancelled = false;
        setInitialData(parseInitialData(result));
        setDataTable(mapToDataTable(result, pageIndex, 5));
        setPageCount(parsePageCount(result));
        setPageLength(parsePageLength(result));
      }
    }

    if (!cancelled) {
      setLoading(false);
    }
  }, [searchKeyword, pageIndex]);

  const handlePageChange = useCallback(
    async ({ pageIndex: index }) => {
      setPageIndex(index);
    },
    [pageIndex]
  );

  const debouncedSearch = debounce((keyword) => {
    batchedUpdates(() => {
      setPageIndex(0);
      setSearchKeyword(keyword);
    });
  }, 500);

  function handleSearch(keyword) {
    debouncedSearch(keyword);
  }

  function handleChangeSearchBy(option) {
    setSearchBy(option);
  }

  function handleClickAddItemGrup() {
    history.push(`/stock-template/edit/${id}/group/new`);
  }

  function handleClickEditName() {
    setTemplateData({ name: initialData.name });

    modalEdit.current.open();
  }

  function handleChangeEditName(event) {
    setTemplateData({ name: event.target.value });
  }

  async function handleClickEditNameDone() {
    setSaving(true);

    await stockTemplate
      .updateSingle(id, {
        json: templateData,
      })
      .then(async (response) => {
        if (!response || (response && !response.ok)) {
          return;
        }

        const result = await response.json();

        if (result && result.status === "success") {
          setInitialData({ ...initialData, name: result.data.name });
        }
      })
      .finally(() => {
        modalEdit.current.close();
      });

    setSaving(false);
  }

  function handleClickEdit(row) {
    history.push(`/stock-template/edit/${id}/group/edit/${row.original.id}`);
  }

  function handleClickDelete(row) {
    setDeleted(false); // reset deleted state
    setDeleteGroupId(row.original.id);
    setDeleteGroupName(row.original.group_name);

    modalDelete.current.unfreeze();
    modalDelete.current.open();
  }

  async function handleClickConfirmDelete() {
    setDeleting(true);

    // prevent modal from closing on keydown escape and on click outside
    modalDelete.current.freeze();

    await stockTemplateGroup
      .deleteSingle(deleteGroupId)
      .then(async (response) => {
        if (!response || (response && !response.ok)) {
          return;
        }

        const result = await response.json();

        if (result && result.status === "success") {
          setDeleting(false);
          setDeleted(true);

          await handleFetchData();
        }
      })
      .finally(() => {
        modalDelete.current.unfreeze();
      });
  }

  function handleClickCancelDelete() {
    modalDelete.current.close();
  }

  function handleClickSuccessDelete() {
    modalDelete.current.close();

    setDeleted(false);
  }

  useEffect(() => {
    return () => {
      if (abortController.current) {
        abortController.current.abort();
      }
    };
  }, []);

  return (
    <>
      <MainLayout headerTitle="Welcome">
        <div className="content-container">
          <ListStockNavTabs />
          <ControlledTable
            loading={loading}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageLength={pageLength}
            onFetchData={handleFetchData}
            onPageChange={handlePageChange}
            columns={headerTable}
            hiddenColumns={["id"]}
            data={dataTable}
            headerClassName="flex-column align-items-stretch"
            header={
              <>
                <div className="px-3 py-1">
                  <Link to="/stock-template">
                    <BackArrow />
                    <span className="text-bbs-primary ml-2">
                      Stock Template
                    </span>
                  </Link>
                </div>
                <div className="d-flex px-2 align-items-center">
                  <div className="table-header-item-wrapper">
                    <h5 className="font-weight-bold">
                      {initialData.name}{" "}
                      <Edit
                        className="cursor-pointer"
                        onClick={handleClickEditName}
                      />
                    </h5>
                  </div>
                  <span className="table-header-divider" />
                  <div className="table-header-item-wrapper">
                    <PrimaryButton onClick={handleClickAddItemGrup}>
                      <Add className="icon-16px" /> Add Item Grup
                    </PrimaryButton>
                  </div>
                </div>
                <div className="px-2" style={{ backgroundColor: "#f9fbfd" }}>
                  <div className="search-bar-wrapper">
                    <SearchBar
                      placeholder={`Search ${searchBy.label}`}
                      onSearch={handleSearch}
                    />
                    <span className="table-header-item-text" />
                    <DropdownButton
                      className="bbs-button-primary button-icon-right button-icon-small button-drop-white"
                      items={[
                        { key: "item", label: "Items" },
                        { key: "group", label: "Nama Grup" },
                        {
                          key: "brand",
                          label: "BRAND + Part Number",
                        },
                      ]}
                      onChange={handleChangeSearchBy}
                    />
                  </div>
                </div>
              </>
            }
            hooks={(hooks) => {
              hooks.visibleColumns.push((visibleColumns) => [
                ...visibleColumns,
                {
                  id: "edit_action",
                  enableRowSpan: true,
                  inheritId: "id",
                  Cell: ({ row }) => (
                    <Edit
                      className="cursor-pointer"
                      onClick={() => handleClickEdit(row)}
                    />
                  ),
                },
                {
                  id: "delete_action",
                  enableRowSpan: true,
                  inheritId: "id",
                  Cell: ({ row }) => (
                    <Delete
                      className="cursor-pointer"
                      onClick={() => handleClickDelete(row)}
                    />
                  ),
                },
              ]);
            }}
          />
        </div>
      </MainLayout>
      <Modal ref={modalDelete}>
        <div className="bbs-modal-form display-flex flex-column align-items-center">
          {deleted ? (
            <>
              <div className="px-1 py-2">
                <CheckCircle className="icon-64px" />
              </div>
              <div className="px-1 py-2">
                <span>
                  Group <strong>{deleteGroupName}</strong> telah dihapus
                </span>
              </div>
              <div className="px-1 py-2">
                <button
                  type="button"
                  className="bbs-button-blue width-10em mx-2"
                  onClick={() => handleClickSuccessDelete()}
                >
                  OK
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="px-1 py-2">
                <Delete className="icon-64px" />
              </div>
              <div className="px-1 py-2">
                <span>Apakah anda yakin ingin menghapus Group ini?</span>
              </div>
              <div className="px-1 py-2">
                <button
                  type="button"
                  className="bbs-button-blue width-10em mx-2"
                  onClick={() => handleClickConfirmDelete()}
                  disabled={deleting}
                >
                  Ya
                </button>
                <button
                  type="button"
                  className="bbs-button-blue inverted width-10em mx-2"
                  onClick={() => handleClickCancelDelete()}
                  disabled={deleting}
                >
                  Tidak
                </button>
              </div>
            </>
          )}
        </div>
      </Modal>
      <Modal ref={modalEdit}>
        <div className="content-container p-4 m-auto width-35em">
          <form>
            <h4 className="font-weight-bold mb-4">Ganti Nama Template</h4>
            <div className="form-group mb-4">
              <label htmlFor="template-name">Nama Template</label>
              <input
                className="form-control"
                id="template-name"
                value={templateData.name}
                onChange={handleChangeEditName}
              />
            </div>
            <div className="form-group">
              <PrimaryButton
                fullWidth
                onClick={handleClickEditNameDone}
                disabled={saving || templateData.name === ""}
              >
                Done
              </PrimaryButton>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default StockTemplateEdit;
