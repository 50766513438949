import { get, post, put, del, withAuth } from "./base";

const getAll = (options) => {
  return get("stock-templates", withAuth(options));
};

const getSearch = (keyword = "", page = 1, signal) => {
  return get(
    `stock-templates?name=${keyword}&page=${page}`,
    withAuth({ signal })
  );
};

const getSingle = (id = "0", page = 1, anotherParams = [], signal) => {
  const params = [];
  let queryParams = "";

  if (page > 0) {
    params.push(`page=${page}`);
  } else {
    params.push("page=1");
  }

  if (anotherParams && anotherParams.length > 0) {
    anotherParams.forEach((param) => {
      params.push(`${param.key}=${param.value}`);
    });
  }

  if (params.length > 0) {
    queryParams = `?${params.join("&")}`;
  }

  return get(`stock-template/${id}${queryParams}`, withAuth({ signal }));
};

const createSingle = (options) => {
  return post("stock-template", withAuth(options));
};

const updateSingle = (id, options) => {
  return put(`stock-template/${id}`, withAuth(options));
};

const deleteSingle = (id) => {
  return del(`stock-template/${id}`, withAuth());
};

export default {
  getAll,
  getSearch,
  getSingle,
  createSingle,
  updateSingle,
  deleteSingle,
};
