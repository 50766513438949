import { get, withAuth } from "./base";

const getSingle = (id = "0", page = 1, anotherParams = [], signal) => {
  const params = [];
  let queryParams = "";

  if (page > 0) {
    params.push(`page=${page}`);
  } else {
    params.push("page=1");
  }

  if (anotherParams && anotherParams.length > 0) {
    anotherParams.forEach((param) => {
      params.push(`${param.key}=${param.value}`);
    });
  }

  if (params.length > 0) {
    queryParams = `?${params.join("&")}`;
  }

  return get(`stock-customer/${id}${queryParams}`, withAuth({ signal }));
};

const getDownload = (id = "0", startDate = null, endDate = null) => {
  const params = [];
  let queryParams = "";

  if (startDate) {
    params.push(`start_date=${startDate}`);
  }

  if (endDate) {
    params.push(`end_date=${endDate}`);
  }

  if (params.length > 0) {
    queryParams = `?${params.join("&")}`;
  }

  const options = { timeout: 1000 * 60 * 5 };

  return get(`download/${id}${queryParams}`, withAuth(options));
};

export default { getSingle, getDownload };
