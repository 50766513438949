import { useEffect, useState } from "react";
import { matchPath } from "react-router-dom";
import MenuItem from "./MenuItem";
import {
  BagCheckFill,
  Box,
  CustomerSupport,
  GoogleDocs,
  Warehouse,
} from "../../components/Icon";
import logo from "../../assets/images/logo.png";
import user from "../../utils/user";
import { lastUpdated } from "../../services/api";

async function fetchLastUpdated() {
  const lastUpdate = await lastUpdated.getLastUpdated();

  if (!lastUpdate || (lastUpdate && !lastUpdate.ok)) {
    return {};
  }

  const result = await lastUpdate.json();
  return result;
}

function SidebarMenu() {
  const [updatedDate, setUpdatedDate] = useState(
    localStorage.getItem("last_updated")
  );

  async function handleUpdateDate() {
    const result = await fetchLastUpdated();
    localStorage.setItem("last_updated", result.updated_at);
    setUpdatedDate(result.updated_at);
  }

  useEffect(() => {
    handleUpdateDate();
  }, []);

  return (
    <div className="shadow sidebar-menu">
      <div className="sidebar-logo">
        <img width="220px" height="100%" src={logo} alt="logo" />
      </div>
      <MenuItem
        show={user.isAdmin() || user.isBranch() || user.isDistributor()}
        linkTo="/list-stock"
        isActive={(match, location) => {
          const childRoutes = [
            "/stock-template",
            "/stock-template/edit/:id",
            "/stock-template/edit/:tid/group/edit/:id",
            "/stock-template/edit/:tid/group/new",
          ];

          if (!match) {
            return matchPath(location.pathname, {
              path: childRoutes,
              exact: true,
              strict: true,
            });
          }

          return true;
        }}
      >
        <Warehouse className="icon-small" />
        <span>List Stock BBS</span>
      </MenuItem>
      <MenuItem show={user.isAdmin() || user.isUser()} linkTo="/stock-customer">
        <Box className="icon-small" />
        <span>Stock Customer</span>
      </MenuItem>
      <MenuItem show={user.isAdmin()} linkTo="/manage-customer">
        <CustomerSupport className="icon-small" />
        <span>Manage Customer</span>
      </MenuItem>
      <MenuItem show={user.isAdmin()} linkTo="/order-acknowledge">
        <GoogleDocs className="icon-small" />
        <span>Order Acknowledge</span>
      </MenuItem>
      <MenuItem show={user.isAdmin()} linkTo="/purchase-order">
        <BagCheckFill className="icon-small" />
        <span>Purchase Order</span>
      </MenuItem>
      <div className="updated-date">
        Updated Date: <br />
        {updatedDate}
      </div>
    </div>
  );
}

export default SidebarMenu;
