import { post } from "./base";
import user from "../../utils/user";

function login({ email, password }) {
  return post("auth/login", {
    json: { email, password },
    hooks: {
      afterResponse: [
        async (request, options, response) => {
          if (response.status === 200) {
            const result = await response.json();

            if (result && result.status === "success") {
              user.setLogin(result);

              let landingPage = "/list-stock";

              if (result.user.role.name === "user") {
                landingPage = "/stock-customer";
              }

              window.location.href = landingPage;
            }
          }
        },
      ],
    },
  });
}

export default { login };
