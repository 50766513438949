function HrDashed() {
  return (
    <hr
      style={{
        borderColor: "#000000",
        borderStyle: "dashed",
        borderWidth: 1,
        margin: 0,
      }}
    />
  );
}

function Form({ data = {} }) {
  return (
    <div className="border mb-2">
      <table style={{ height: "1px" }} className="m-0 table table-borderless">
        <tbody>
          <tr>
            <td className="h-100" style={{ width: "50%" }}>
              <div className="h-100 d-flex flex-column">
                <div className="border border-dark rounded">
                  <div className="px-3 py-2">
                    <strong>PT. BANJAR BEARING SENTOSA</strong>
                  </div>
                  <HrDashed />
                  <div className="px-3 py-2">
                    Jl. Gatot Subroto No. 74 RT.032 <br />
                    Kuripan - Banjarmasin Timur <br />
                    Kota Banjarmasin Kalimantan Selatan
                  </div>
                </div>
              </div>
            </td>
            <td className="h-100" style={{ width: "50%" }}>
              <div className="w-100 h-100 d-flex align-items-center flex-row-reverse">
                <h1>
                  <strong>DELIVERY ORDER</strong>
                </h1>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <table style={{ height: "1px" }} className="m-0 table table-borderless">
        <tbody>
          <tr>
            <td style={{ width: "50%" }}>
              <table
                style={{ height: "1px" }}
                className="table table-borderless"
              >
                <tbody>
                  <tr>
                    <td style={{ width: "60px" }} className="px-0 pt-0">
                      Bill To <br />
                    </td>
                    <td style={{ width: "50px" }} className="pt-0">
                      : <br />
                    </td>
                    <td className="px-0 pt-0">
                      <div className="border border-dark rounded">
                        <div className="px-3 py-2">
                          <strong>{data.billto1}</strong>
                          <br />
                          <span>{data.billto2 || <br />}</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "60px" }} className="px-0 pb-0">
                      Ship To <br />
                    </td>
                    <td style={{ width: "50px" }} className="pb-0">
                      : <br />
                    </td>
                    <td className="px-0 pb-0">
                      <div className="border border-dark rounded">
                        <div className="px-3 py-2">
                          <strong>{data.shipto1}</strong>
                          <br />
                          <span>
                            {data.shipto2} - {data.shipto3 || <br />}
                            <br />
                            {data.shipto4}
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td className="h-100" style={{ width: "50%" }}>
              <div className="h-100 d-flex flex-column">
                <div
                  className="border border-dark rounded"
                  style={{ marginBottom: "1.5rem" }}
                >
                  <table className="m-0 table table-borderless table-dashed-inside">
                    <tbody>
                      <tr>
                        <td className="pt-2" style={{ width: "50%" }}>
                          <span>Delivery Date</span>
                          <br />
                          <strong>{data.invoicedate}</strong>
                        </td>
                        <td className="pt-2" style={{ width: "50%" }}>
                          <span>Delivery No</span>
                          <br />
                          <strong>{data.invoiceno}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td className="pt-2" style={{ width: "50%" }}>
                          <span>Ship Via</span>
                          <br />
                          <strong>-</strong>
                        </td>
                        <td className="pt-2" style={{ width: "50%" }}>
                          <span>PO. No.</span>
                          <br />
                          <strong>-</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <table style={{ height: "1px" }} className="m-0 table table-borderless">
        <tbody>
          <tr>
            <td>
              <div className="table-rounded-container">
                <table className="m-0 table table-borderless table-bordered-y">
                  <thead>
                    <tr>
                      <th className="text-center">Item</th>
                      <th className="text-center">Item Description</th>
                      <th className="text-center">Quantity</th>
                      <th className="text-center">Serial Number</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(data.ITEMS || []).map((item, index) => {
                      const key = `${item.ITEMNO}_${index}`;
                      return (
                        <tr key={key}>
                          <td>{item.ITEMNO}</td>
                          <td>{item.ITEMDESCRIPTION}</td>
                          <td className="text-center">
                            {Number.parseInt(item.QUANTITY, 10)}
                            {item.ITEMUNIT}
                          </td>
                          <td />
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <table style={{ height: "1px" }} className="m-0 table table-borderless">
        <tbody>
          <tr>
            <td style={{ width: "50%" }}>
              <table
                style={{ height: "1px" }}
                className="table table-borderless"
              >
                <tbody>
                  <tr>
                    <td>
                      Prepared By <br />
                      <br />
                      <br />
                      <hr />
                      Date
                    </td>
                    <td>
                      Approved By <br />
                      <br />
                      <br />
                      <hr />
                      Date
                    </td>
                    <td>
                      Shipped By <br />
                      <br />
                      <br />
                      <hr />
                      Date
                    </td>
                    <td>
                      Received By <br />
                      <br />
                      <br />
                      <hr />
                      Date
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td style={{ width: "50%" }}>
              <div className="mt-4 h-75 border border-dark rounded position-relative">
                <span
                  className="position-absolute bg-white px-1 mx-2"
                  style={{ top: "-0.9em" }}
                >
                  Description
                </span>
                <div className="px-3 py-2">{data.description}</div> <br />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Form;
